// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  BASE_API_URL: 'https://kushmapz-admin.dedicateddevelopers.us/api/',
  IMAGE_BASE_URL: 'https://kushmapz-admin.dedicateddevelopers.us/uploads/',
  G_CLIENT_ID: '970290401765-jakkn4oc1lcfdoqb6lndfdmc5ik0i89c.apps.googleusercontent.com',
  // FB_APP_ID: '287424653355669',
  // 310157074023254
  FB_APP_ID: '3188517518035522',
  APPLE_ID: 'com.wts.kushmapzService',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const DefaultLocation = {
  city: "Toronto",
  country: "CA",
  state: "Ontario"
};
