<div class="modalwrap">
    <div class="container">
        <div class="row">
            <h3 style="color: #4ABA80;">You get a free pin drop!</h3>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>Select location of your choice</label>
            </div>
            <div class="col-md-12 loc-col">
                <img src="./assets/images/location.svg" alt="" class="location">
                <input type="text" name="palce-inp" (input)="getPlace('auto')" id="auto">
            </div>
        </div>
        <div class="row">
            <button type="button" class="btn btn-submit mb-4" (click)="submit()">Submit</button>
        </div>
    </div>
</div>
