import { Router } from '@angular/router';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() data: any;

  @Output() searchStr = new EventEmitter<any>();
  @Output() homeBanner = new EventEmitter<any>();
  inpStr: any = '';
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log("data",this.data);
    
  }

  tosearchStr(): void {
    if (this.inpStr) {
      this.searchStr.emit(this.inpStr);
      this.inpStr = '';
    }
  }

  bannerButtonEmit(data: any): void {
    // this.homeBanner.emit(ID);
    if (data?.merchantId) {
      this.router.navigate(['/retailer-details', data?.merchantId, data?.id]);
    }
  }

}
