import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@project/material.module';
import { RouterModule } from '@angular/router';
import { SignupConfirmationComponent } from './signup-confirmation/signup-confirmation.component';
import { ConfirmEditComponent } from './confirm-edit/confirm-edit.component';
import { ProfileFieldsEditComponent } from './profile-fields-edit/profile-fields-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgOtpInputModule } from 'ng-otp-input';
import { AddReviewPopupComponent } from './add-review-popup/add-review-popup.component';
import { NgxStarsModule } from 'ngx-stars';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { FreePinDropComponent } from './free-pin-drop/free-pin-drop.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
// import { DatePickerComponent } from './date-picker/date-picker.component';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { AutocompleteComponent } from '../address/google-places/google-places.component';
import { AddressModule } from '../address/address.module';
import { CartModalComponent } from './cart-modal/cart-modal.component';
import { AgeVerifiedComponent } from './age-verified/age-verified.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SuccessfullPaymentComponent } from './successfull-payment/successfull-payment.component';


const COMPONENTS = [SignupConfirmationComponent,
  ConfirmEditComponent, 
  ProfileFieldsEditComponent,
  AddReviewPopupComponent,
  PaymentDialogComponent,
  SuccessfullPaymentComponent,
  ];

@NgModule({
declarations: [
  COMPONENTS,
  FreePinDropComponent,
  DatePickerComponent,
  ConfirmationComponent,
  CartModalComponent,
  AgeVerifiedComponent,
  
  
  // DatePickerComponent
],
exports: [
  COMPONENTS
],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    NgxStarsModule,
    NgxMaskModule.forChild(),
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory,
    // })
    // AutocompleteComponent
    AddressModule,
    SlickCarouselModule
  ],
  providers: [
    
  ]
})
export class ModalModule { }
