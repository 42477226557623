<div class="modalwrap">
    <div class="container" [formGroup]="feedbackForm">

        <h4 class="d-flex justify-content-between">Review <button class="close btn" (click)="closeDialog()">x</button></h4>

        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <div class="rating d-inline-block">
                        <ngx-stars [size]="3" [wholeStars]="true" [initialStars]="data?.isEdit ? data?.rating : 0" [color]="'#FFC107'" (ratingOutput)="onRatingSet($event)"></ngx-stars>
                        <mat-error *ngIf="feedbackForm.controls.rating.touched && feedbackForm.controls.rating.hasError('required')">
                            Please give a rating.
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <input type="text" placeholder="Review title" formControlName="title">
                <mat-error *ngIf="feedbackForm.controls.title.touched && feedbackForm.controls.title.hasError('required')">
                    This is mandatory.
                </mat-error>
                <mat-error *ngIf="feedbackForm.controls.title.touched && feedbackForm.controls.title.hasError('pattern')">
                    Only whitespaces are not allowed.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <textarea placeholder="How was your experience?" formControlName="review"></textarea>
                <mat-error *ngIf="feedbackForm.controls.review.touched && feedbackForm.controls.review.hasError('required')">
                    This is mandatory.
                </mat-error>
                <mat-error *ngIf="feedbackForm.controls.review.touched && feedbackForm.controls.review.hasError('pattern')">
                    Only whitespaces are not allowed.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <button (click)="closeDialog()" class="cancelbtn d-inline-block btn-danger mr-2">Cancel</button>
                <button *ngIf="!data.isEdit" (click)="submitReview()" class="greenbtn d-inline-block">Submit</button>
                <button *ngIf="data.isEdit" (click)="editReview()" class="greenbtn d-inline-block">Submit</button>
            </div>


        </div>
    </div>
</div>