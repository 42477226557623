import { EventService } from './event.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@envconfig';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})


export class ApiService {

  private BASE_API_URL = environment.BASE_API_URL;
  httpOptions: { headers: HttpHeaders; };
  TOKEN: string;

  selectedCatSubCat: any = undefined;
  countDown = 0;
  beginTimeHasIssue: any;
  endTimeHasIssue: any;
  return_val: boolean = false

  constructor(
    private http: HttpClient,
    private event: EventService,
    private storage: StorageService,
    private router: Router,
  ) {
    //console.log(this.storage.getUser());
    this.event.isLogin.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.TOKEN = this.storage.getDataField('token');
        this.setHeader(this.TOKEN);
      } else {
        this.setHeader('false');
      }
    });
  }

  async setHeader(TOKEN: string): Promise<any> {
    if (TOKEN !== 'false') {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
          'x-access-token': TOKEN
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
        })
      };
    }
  }

  /*get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BASE_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }*/

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.countForLogout();
    return this.http.get(`${this.BASE_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(map((res: any) => {
        // //console.log(res);

        if (res.status === 401) {
          //console.log("res.status === 401",path);

          let userType = '';
          if (this.storage.getDataField('role') === 'merchant') {
            userType = 'merchant';
          } else if (this.storage.getDataField('role') === 'user') {
            userType = 'user';
          }
          this.alert('Your session has expired. Please log in.', 'warning');
          this.storage.clearUser();
          this.event.setLoginEmmit(false);
          if (userType === 'merchant') {
            this.router.navigate(['/login-merchant']);
          } else if (userType === 'user') {
            this.router.navigate(['/login']);
          }
        } else {
          return res;
        }
      }),
        catchError(this.formatErrors));
  }

  /*post(path: any, body: object = {}, reportProgress = false): Observable<any> {
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(
        catchError(this.formatErrors)
      );
  }*/
  post(path: any, body: object = {}, reportProgress = false): Observable<any> {
    this.countForLogout();
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(
        map((res: any) => {
          if (res.status === 401) {
            let userType = '';
            if (this.storage.getDataField('role') === 'merchant') {
              userType = 'merchant';
            } else if (this.storage.getDataField('role') === 'user') {
              userType = 'user';
            }
            this.alert('Your session has expired. Please log in.', 'warning');
            this.storage.clearUser();
            this.event.setLoginEmmit(false);
            if (userType === 'merchant') {
              this.router.navigate(['/login-merchant']);
            } else if (userType === 'user') {
              this.router.navigate(['/login']);
            }
          } else {
            return res;
          }
        }),
        catchError(this.formatErrors));
  }

  postMultiData(path: string, file: FormData, reportProgress = false): Observable<any> {
    this.countForLogout();
    let httpOptionsimg;
    if (this.storage.getDataField('token')) {
      httpOptionsimg = {
        headers: new HttpHeaders({
          Accept: 'multipart/form-data',
          'x-access-token': this.TOKEN
        }),
        reportProgress
      };
    } else {
      httpOptionsimg = {
        headers: new HttpHeaders({
          Accept: 'multipart/form-data',
        }),
        reportProgress
      };
    }
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(
        map((res: any) => {
          if (res.status === 401) {
            let userType = '';
            if (this.storage.getDataField('role') === 'merchant') {
              userType = 'merchant';
            } else if (this.storage.getDataField('role') === 'user') {
              userType = 'user';
            }
            this.alert('Your session has expired. Please log in.', 'warning');
            this.storage.clearUser();
            this.event.setLoginEmmit(false);
            if (userType === 'merchant') {
              this.router.navigate(['/login-merchant']);
            } else if (userType === 'user') {
              this.router.navigate(['/login']);
            }
          } else {
            return res;
          }
        }),
        catchError(this.formatErrors));
  }
  alert(message: string, type: any, duraion?: number): any {
    return Swal.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duraion ? duraion : 2000,
    });
  }

  alertModal(message: string, type: any, CancelButton = false, isIcon?: boolean): any {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: false,
      showCancelButton: CancelButton,
      // confirmButtonText: 'Ok',
      timer: 2000,
    });
  }

  alertModalOk(message: string, type: any, CancelButton = false, isIcon?: boolean): any {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      cancelButtonText: 'OK',
      showCancelButton: CancelButton,
      // confirmButtonText: 'Ok',
      timer: 2000,
    });
  }

  async alertConfirmation(title?: any, confirmButtonText?: any): Promise<boolean> {
    let flag = false;
    await Swal.fire({
      title: title ? title : 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#2101f5',
      // cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText ? confirmButtonText : 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then((result: any) => {
      if (result && result.value) {
        flag = true;
      } else {
        flag = false;
      }
    });
    return new Promise((resolve, reject) => {
      resolve(flag);
    });
  }

  getGeoLocation(): any {
    this.countForLogout();
    const url = 'https://geolocation-db.com/json/'; // 'https://extreme-ip-lookup.com/json/';
    return this.http
      .get(url)
      .pipe(
        catchError(this.formatErrors)
      );
  }

  private formatErrors(error: any): any {
    return throwError(error.error);
  }

  // openOrClosed(data: any): boolean {
  //   const today = new Date();
  //   const now = moment(today).format('HH:mm');
  //   const nowDay = moment(today).format('dddd');

  //   const tmp = data.open_close_time.filter((ele: any) => ele.day.toLowerCase() === nowDay.toLowerCase());
  //   if (tmp.length) {
  //     const startTime = moment(tmp[0].open, 'hh:mm A').format('HH:mm');
  //     const endTime = moment(tmp[0].close, 'hh:mm A').format('HH:mm');

  //     if (+now.toString().split(':')[0] >= +endTime.toString().split(':')[0]) {
  //       return false;
  //     } else {
  //       if (+now.toString().split(':')[0] >= +startTime.toString().split(':')[0]) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //   } else {
  //     return false;
  //   }
  // }


  
  openOrClosed(data: any): boolean {
    const today = new Date();
    const now = moment(today).format('HH:mm');
    const nowDay = moment(today).format('dddd');
    data.open_close_time.every((ele: any) => {
    // console.log("data",ele);

      if (ele.day.toLowerCase() === nowDay.toLowerCase() && ele?.status === 'Open') {
        ele.timing.every((res: any) => {
          const startTime = moment(res.open, 'hh:mm A').format('HH:mm');
          const endTime = moment(res.close, 'hh:mm A').format('HH:mm');
          // console.log("startTime",startTime);
          // console.log("endTime",endTime);
          
          if (+now.toString().split(':')[0] <= +endTime.toString().split(':')[0] && +now.toString().split(':')[0] >= +startTime.toString().split(':')[0]) {
            // console.log("aaaa->>end");
            this.return_val = true;
            return false;
          }
          return true
        })
      }
     return true;
    })
    return this.return_val;
  }


  addToCart(cartObj: any) {

    const items = [];
    let doExist = false;
    if (localStorage.getItem('my-cart-items')) {
      let existingItems: any = localStorage.getItem('my-cart-items');
      existingItems = JSON.parse(existingItems);
      if (existingItems?.length) {
        existingItems?.forEach((item: any) => {
          if (item._id === cartObj._id) {
            doExist = true;
            item.quantity += cartObj.quantity;
          }
          items.push(item);
        });
      }
    }
    if (!doExist) {
      items.push(cartObj);
    }
    localStorage.setItem('my-cart-items', JSON.stringify(items));
    this.alert('Product added to cart successfully', 'success');
  }
  /*getlatlng(sellerObj: any){
    let address = sellerObj.city + ',' + sellerObj.state + ',' + sellerObj.country;
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address).subscribe(latLng: any)
  }*/

  async showCoockiPolicy(content?: any): Promise<boolean> {
    let flag = false;
    await Swal.fire({
      title: 'Accept Coocki?',
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#2101f5',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      html: content,
      allowOutsideClick: false
    }).then((result: any) => {
      if (result && result.value) {
        flag = true;
      } else {
        flag = false;
      }
    });
    return new Promise((resolve, reject) => {
      resolve(flag);
    });
  }

  countForLogout(): void {
    var expires = new Date();
    expires.setHours(24);
    if (this.storage.getUser()?.role === 'merchant') {
      // localStorage.setItem('kush-access-time', expires.getTime().toString());
    }
  }
}
