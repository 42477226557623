import { MapsAPILoader } from '@agm/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { StorageService } from './services/storage.service';
import { EventService } from './services/event.service';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'default';
  lastAccessTime: any;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    private api: ApiService,
    private event: EventService,
    private mapsAPILoader: MapsAPILoader,
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    private storage: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.checkSessionExp();


    // localStorage.setItem('kush-access-time', new Date().toString());
    // setInterval(() => {
    //   this.checkSessionExp();
    // }, 60000)

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    ////////////
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'allow') {
          this.cookieService.set('IS_COOKIE_ALLOWED', 'true');
          this.ccService.destroy();
        } else {
          this.cookieService.delete('IS_COOKIE_ALLOWED');
        }
      });
    let isCookieAllowed = this.cookieService.get('IS_COOKIE_ALLOWED');
    if (isCookieAllowed == 'true') {
      this.ccService.destroy();
    }
  }

  checkSessionExp(): void {
    // if (this.storage.getUser()?.role === 'merchant') {
    //   this.lastAccessTime = localStorage.getItem('kush-access-time');
    //   if ((new Date().getTime() - parseInt(this.lastAccessTime)) >= 20*60*1000) {
    //     this.storage.clearUser();
    //     this.event.setLoginEmmit(false);
    //     sessionStorage.removeItem('recent-view');
    //     this.router.navigate(['/home']);
    //     this.api.alertModal('Your session has been expired', 'warning');
    //   }
    // }
  }



  getCurrentLocation(position: any) {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };

      // const latlng = {lat: 36.7783, lng: -119.4179}
      // tslint:disable-next-line:object-literal-key-quotes
      geocoder.geocode({ 'location': latlng }, (results) => {
        const val = results.filter(ele => ele.types.indexOf('country') !== -1);
        if (val && val.length > 0) {
          // sessionStorage.setItem('currCountry', val[0].address_components[0].long_name);
          // this.event.setCurrentLocation(val[0].address_components[0].long_name);
        } else {
          this.api.alert("Some error occured while fetching current location", 'error');
        }
      });
    });
  }

  ngOnDestroy() {
    if (localStorage.getItem('currPos')) {
      localStorage.removeItem('currPos')
    }

    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}
