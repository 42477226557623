
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoaderModule } from './loader/loader.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptor/interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ServicesModule } from './services/services.module';
import { ModalModule } from './modal/modal.module';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule } from 'ngx-mask';
import { AgmOverlays } from 'agm-overlays';
import * as moment from 'moment';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AddressModule } from './address/address.module';

export function momentAdapterFactory(): any {
  return adapterFactory(moment);
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost'
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#50bb80'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>,
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>,
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a> and our
      <a aria-label="learn more about our referal policy" tabindex="3" class="cc-link" href="{{refHref}}" target="_blank">{{refLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie policy',
    cookiePolicyHref: '/legal/cookie-policy',

    privacyPolicyLink: 'Privacy policy',
    privacyPolicyHref: '/legal/privacy-policy',

    tosLink: 'Terms of use',
    tosHref: '/legal/terms-and-use',


    refLink: 'Referral program',
    refHref: '/legal/referral-program',
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LoaderModule,
    HttpClientModule,
    ModalModule,
    RecaptchaModule,
    NgxMaskModule.forRoot(),
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyARJNZxHiaeqO0QzTTXT920VYrIBFeIRZ4',
      // AIzaSyARJNZxHiaeqO0QzTTXT920VYrIBFeIRZ4  >>> new key
      // AIzaSyDGQPzVoWs3-LPIHFGD5XsEwBAG9mgBl_A   >>> client key
      // AIzaSyCxFfgqlu5znU2Us0vT5qjYSbxKPFUhJDs >>> demo key working
      libraries: ['places'],
      language: 'en'
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    // GooglePlaceModule
    AddressModule
  ],
  providers: [
    ServicesModule,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LfbLvUaAAAAAI0eP9DMdt4_uF0m4JoVhFV-ALWG'
      } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
