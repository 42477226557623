import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-add-review-popup',
  templateUrl: './add-review-popup.component.html',
  styleUrls: ['./add-review-popup.component.scss']
})
export class AddReviewPopupComponent implements OnInit {
  feedbackForm: FormGroup
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddReviewPopupComponent>, private fb: FormBuilder,
  private api: ApiService) { }

  ngOnInit(): void {
    this.formInit()
  }

  formInit(){
    this.feedbackForm = this.fb.group({
      rating: ['', [Validators.required]],
      title: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      review: ['', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]]
    });

    if(this.data.isEdit){
      this.feedbackForm.patchValue({
        rating: this.data.rating,
        title: this.data.title,
        review: this.data.review
      })
    }
  }

  onRatingSet(event:any){
    this.feedbackForm.patchValue({
      rating: event
    })
  }

  submitReview(){
    if(this.feedbackForm.valid){
      const obj = new FormData();
      for(const key in this.feedbackForm.value){
        obj.set(key, this.feedbackForm.value[key]);
      }
    let apiUrl: any;
     if(this.data.type === 'product'){
      obj.set('productId', this.data.id);
      apiUrl = 'review-rating/product';
     }else if(this.data.type === 'retailer'){
      obj.set('merchantId', this.data.id);
      apiUrl = 'review-rating/store'
     }
    
     if(apiUrl){
      this.api.postMultiData(apiUrl, obj).subscribe((res:any) => {
        if(res.status === 200){
          this.api.alert(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.api.alert(res.message, 'warning');
        }
      }, err => {
        this.api.alert('Something went wrong...!!!', 'error');
      })
     }

    }else{
      this.feedbackForm.markAllAsTouched();
    }
  }

  editReview(){
    if(this.feedbackForm.valid){
      const obj = new FormData();
      for(const key in this.feedbackForm.value){
        obj.set(key, this.feedbackForm.value[key]);
      }
      obj.set('type', this.data.type);
      obj.set('id', this.data.id);
      this.api.postMultiData('user/review-update', obj).subscribe((res:any) => {
        if(res.status === 200){
          this.api.alert(res.message, 'success');
          this.dialogRef.close(true);
        }else{
          this.api.alert(res.message, 'warning');
        }
      }, err => {
        this.api.alert('Something went wrong...!!!', 'error');
      })
    }else{
      this.feedbackForm.markAllAsTouched();
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
