
<div class="modalwrap">
    <div class="modalClose" *ngIf="!selectedDate" (click)="onNoClick()">
        <span class="material-icons">
            close
        </span>                
    </div>

    <mat-dialog-content>
        <img src="./assets/images/logo.svg" alt="" class="d-block mx-auto" id="logo">

        <div id="step1" class="d-block">
            <!-- <h1>Are You 19 Or Older?</h1> -->
            <h1>Are you 19 years old?</h1>

            <div class="d-flex justify-content-center">
                <button class="btn btn-primary rounded-pill px-md-5 px-auto py-2 mr-3 mb-3" (click)="openCalendar()">Yes</button>
                <button class="btn btn-danger rounded-pill px-md-5 px-auto py-2 mb-3" (click)="noOpenCalendar()">No</button>
            </div>

            <p>You must verify that you are 19 years of age or older to enter the site.</p>
        </div>

        <div id="calendarView">
            <h1>Enter Your Birthday</h1>
            <!-- <form> -->
                <div class="row mx-0">
                    <div class="col-md-12">
                        <mat-calendar [maxDate]="maxDt" [selected]="selectedDate" (selectedChange)="onSelect($event)"></mat-calendar>
                    </div>
                </div>
                <p>We’ll Update your profile so you don’t have to do this again!</p>
                <button *ngIf="selectedDate === undefined" class="btn btn-primary rounded-pill px-5 py-2 mb-3 d-block mx-auto disabled">Submit</button>
                <button *ngIf="selectedDate" class="btn btn-primary rounded-pill px-5 py-2 mb-3 d-block mx-auto" (click)="showStep3()">Submit</button>

            <!-- </form> -->
        </div>

        <div id="step3">
            <ng-container *ngIf="selectedDate">
                <span class="material-icons text-primary">
                    check_circle
                </span>
                <h1>Update Successful!</h1>
            </ng-container>

            <ng-container *ngIf="selectedDate === undefined">
                <span class="material-icons text-primary">
                    close
                </span>
                <h1>Sorry! You are not allowed to register.</h1>
            </ng-container>
        </div>
    </mat-dialog-content>
</div>
