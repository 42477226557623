import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss']
})
export class CartModalComponent implements OnInit {
  productQuantity: number = 1;
  choosePrice:any;
  onSubmit:boolean = false;
  weightPrice:any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<CartModalComponent>,) { }

  ngOnInit(): void {
    //console.log(this.modalData);
    this.weightPrice = this.modalData?.product?.weightPrice;
    if(this.weightPrice.length > 0){
      this.choosePrice = this.weightPrice[0]
    }
  }
  updateProductQuantity(state: any) {
    switch (state) {
      case "add":
        this.productQuantity += 1;
        break;
      case "remove":
      if (this.productQuantity > 0) {
        this.productQuantity -= 1;
      }
        break;
    }
  }
  addToCart(){
    this.onSubmit = true;
    if (this.productQuantity != 0) {
      let body = {
        product_id:this.modalData.product._id,
        quantity:this.productQuantity,
        weight: this.choosePrice?.weight
      }
      
      // return
      this.dialogRef.close({status:true,body})
    }
  }

}
