import { MapsAPILoader } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { SignupConfirmationComponent } from '@project/modal/signup-confirmation/signup-confirmation.component';
import { AgeVerifiedComponent } from '@project/modal/age-verified/age-verified.component';
import * as moment from 'moment';

// import * as angular from "angular";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() data: any;
  menuBackDrop: any;
  mainMenuWrap: any;
  profileDrop: any;

  categories: any;
  brands: any;
  BASEURL = environment.IMAGE_BASE_URL;
  isLogin = false;
  loggedInUser: any = undefined;
  showOpts = false;

  locationInp: any = '';
  noOfItems = 0;
  searchText: any = '';
  seachResult: any = [];
  searching: boolean = false;
  homePageStatic: any;
  locationSearchResult: boolean = false;
  locationSearchHistory: any = [];
  type_status:boolean = true
  current_date:any

  constructor(private api: ApiService, private event: EventService,
    private storage: StorageService, private router: Router, private mapsAPILoader: MapsAPILoader, private dialog: MatDialog) {
    this.getLocationAccess();
    this.isAdultUser()
    this.locationHistory();
  }

  ngOnInit(): void {
    this.current_date = moment()
    console.log(this.current_date);
    
    this.event.changeMapCenter.subscribe(pos => {
      this.getLocationDetails(pos)

    })
    this.getBrands();
    this.getCatergories();
    this.getBannerData();
    this.event.isLogin.subscribe((res: boolean) => {
      // console.log(res,'isLogin');
      if (this.storage.getDataField('role') === 'user') {
        this.isLogin = res;
        if (res) {
          this.getProfile();
        }
      } else {
        this.isLogin = false;
      }
    });

    this.event.isProfileUpdated.subscribe((res: boolean) => {
      if (res) {
        this.getProfile();
      }
    });
  }
  locationHistory() {
    this.locationSearchHistory = localStorage.getItem("locationSearchHistory");
    if (this.locationSearchHistory) {
      this.locationSearchHistory = JSON.parse(this.locationSearchHistory);
    } else {
      this.locationSearchHistory = [];
    }
  }
  isAdultUser() {

    if (this.storage.getTempData()?.IS_ADULT_USER) {
    } else {
      const dialogRef = this.dialog.open(AgeVerifiedComponent);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.storage.setTempData({ IS_ADULT_USER: true })
        } else {
          this.api.alert('You need to be 19 yrs or older in order to access the site', 'warning')
          this.router.navigate(['/login']);
        }
      })
    }
  }

  getLocationAccess(): void {
    if (sessionStorage.getItem('CURRENT_SEARCH_LOC')) {
      let pos: any;
      pos = sessionStorage.getItem('CURRENT_SEARCH_LOC');
      pos = JSON.parse(pos);
      this.locationInp = pos.address;
      this.event.setCurrLocationEmit(true);
      this.checkAvailableLocation(pos);
    } else {
      this.searchByCurrentLocation();
    }
  }
  searchByCurrentLocation() {
    this.type_status = true
    
    const navigatorLocationOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    if (navigator.geolocation) {
      
      navigator.geolocation.getCurrentPosition((position: any) => {
        console.log(position);
        
        if (position) {
          const pos = {
            coords: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          };
          this.getCurrentLocation(pos);
        }
      }, (error: any) => {
        console.log(error);
        // {"lat":43.653226,"lng":-79.3831843,"country":"CA","state":"Ontario","city":"Toronto","country_long":"Canada","address":"Toronto Toronto, ON, Canada"}
        this.locationInp = "Toronto, ON, Canada";
        const currPos = {
          lat: 43.653226,
          lng: -79.3831843,
          address: "Toronto, ON, Canada",
          country: "CA",
          country_long: "Canada",
          state: "Ontario",
          city: "Toronto"
        };
        sessionStorage.setItem('CURRENT_SEARCH_LOC', JSON.stringify(currPos));
        this.checkAvailableLocation(currPos);
        this.event.setCurrLocationEmit(true);
      },navigatorLocationOptions);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  getLocationDetails(position: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
    const options = {
      componentRestrictions: { country: ['US', 'CA'] },
      // componentRestrictions: { country: 'US', postalCode: 75001 }, // to restrict the search in a country
      // types: ['street_address']  // 'establishment' / 'address' / 'geocode' / 'street_address'
      types: ['geocode', 'street_address']
      // componentRestrictions: { language: 'en'}
    };
    // geocoder.geocode()
    geocoder.geocode({
      'location': latlng,
    }, (results) => {
      let val: any;
      if (results) {
        val = results.filter(ele => ele.types.indexOf('locality') !== -1);
      }
      if (val && val.length > 0) {
        let str = '';
        let city: any;
        let state: any;
        let country: any;
        let country_long: any;
        val[0].address_components.forEach((ele: any) => {
          if (ele.types[0] === 'locality') {
            city = ele.long_name,
              str += `${ele.long_name}, `;
          }
          if (ele.types[0] === 'administrative_area_level_1') {
            state = ele.long_name;
            str += `${ele.short_name}, `;
          }
          if (ele.types[0] === 'country') {
            country = ele.short_name;
            country_long = ele.long_name;
            str += `${ele.short_name}`;
          }
        });
        const currPos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          address: str,
          country,
          state,
          city,
          country_long
        };

        // this.locationInp = str;
        // sessionStorage.setItem('CURRENT_SEARCH_LOC', JSON.stringify(currPos));
        // this.checkAvailableLocation(currPos);
        // this.event.setCurrLocationEmit(true);
      } else {
      }
    });
  }

  getCurrentLocation(position: any): void {
    this.mapsAPILoader.load().then(() => {

      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
      geocoder.geocode({ 'location': latlng }, (results) => {
        
        console.log(results);
        let val: any = results;
        // if (results) {
        //   val = results.filter(ele => ele.types.indexOf('locality') !== -1);
        // }
        
        
        if (val && val.length > 0) {
          console.log("lll");
          let str = '';
          let city: any;
          let state: any;
          let country: any;
          let country_long: any;
          val[0].address_components.forEach((ele: any) => {
            if (ele.types[0] === 'locality') {
              city = ele.long_name,
                str += `${ele.long_name}, `;
            }
            if (ele.types[0] === 'administrative_area_level_1') {
              state = ele.long_name;
              str += `${ele.short_name}, `;
            }
            if (ele.types[0] === 'country') {
              country = ele.short_name;
              country_long = ele.long_name;
              str += `${ele.short_name}`;
            }
          });
          const currPos = {
            first_load:this.type_status,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            address: str,
            country,
            state,
            city,
            country_long
          };
          console.log("currPos",currPos);
          
          this.locationInp = str;
          sessionStorage.setItem('CURRENT_SEARCH_LOC', JSON.stringify(currPos));
          this.checkAvailableLocation(currPos);
          this.event.setCurrLocationEmit(true);
        } else {
          // this.api.alert('Some error occured while fetching current location', 'error');
        }
      });
    });
  }

  current_location: any = '/assets/images/my_location.svg';
  search_location: any = '/assets/images/search_location.svg';
  location_history: any = '/assets/images/location_history.svg';
  
  getPlace(inputId: string): void {
    
    const input = document.getElementById(inputId) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: ['US', 'CA'] },
      // componentRestrictions: { country: 'US', postalCode: 75001 }, // to restrict the search in a country
      // types: ['street_address']  // 'establishment' / 'address' / 'geocode' / 'street_address'
      // types: ['geocode','street_address'] 
      // componentRestrictions: { language: 'en'}
    };

    // new map listing implement

    let displaySuggestions = (predictions: any, status: any) => {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        alert(status);
        return;
      }

      let previous_ul = document.getElementById('result');
      if (previous_ul) {
        previous_ul.innerHTML = '';
      }
      let li = document.createElement('li');
      li.addEventListener('mousedown', (e) => {
        this.locationSearchResult = false;
        this.onClickLocation('current_location', ''); //your typescript function
      });
      let figure = document.createElement('figure');
      let img = document.createElement('img');
      img.src = this.current_location;
      figure.appendChild(img);
      li.appendChild(figure);
      let span = document.createElement('span');
      span.innerText = 'Current Location';
      li.appendChild(span);
      previous_ul?.append(li);
      //add location history
      if (this.locationSearchHistory.length > 0) {
        for (let index = 0; index < this.locationSearchHistory.length; index++) {
          const element = this.locationSearchHistory[index];
          let li = document.createElement('li');
          li.addEventListener('mousedown', (e) => {
            this.locationSearchResult = false;
            this.onClickLocation(element?.place_id, element?.location_name); //your typescript function
          });
          let figure = document.createElement('figure');
          let img = document.createElement('img');
          img.src = this.location_history;
          figure.appendChild(img);
          li.appendChild(figure);
          let span = document.createElement('span');
          span.innerText = element?.location_name;
          li.appendChild(span);
          previous_ul?.append(li);
        }
      }
      //location history end

      for (let i = 0; i < predictions.length; i++) {
        let li = document.createElement('li');
        li.addEventListener('mousedown', (e) => {
          this.locationSearchResult = false;
          this.onClickLocation(predictions[i].place_id, predictions[i].description); //your typescript function
        });
        let figure = document.createElement('figure');
        let img = document.createElement('img');
        img.src = this.search_location;
        figure.appendChild(img);
        li.appendChild(figure);
        let span = document.createElement('span');
        span.innerText = predictions[i].description;
        li.appendChild(span);
        previous_ul?.append(li);
      }
    };


    let service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({
      input: input.value,
      componentRestrictions: { country: ['US', 'CA'] }
    }, displaySuggestions);


    // new map listing implement end


    // const place = new google.maps.places.Autocomplete(input, options);
    // google.maps.event.addListener(place, 'place_changed', (_result) => {


    //   let selectedPlace: any;
    //   selectedPlace = place.getPlace();
    //   let str = '';
    //   let city: any;
    //   let state: any;
    //   let country: any;
    //   let country_long: any;
    //   if (selectedPlace) {

    //     // this.locationInp = str = selectedPlace.name + " " + selectedPlace.formatted_address;        
    //     this.locationInp = str =  selectedPlace.formatted_address;      
    //     selectedPlace.address_components.forEach((ele: any) => {
    //       if (ele.types[0] === 'locality') {
    //         city = ele.long_name;
    //       }
    //       if (ele.types[0] === 'administrative_area_level_1') {
    //         state = ele.long_name;
    //       }
    //       if (ele.types[0] === 'country') {
    //         country = ele.short_name;
    //         country_long = ele.long_name;
    //       }

    //     });
    //     const currPos = {
    //       lat: selectedPlace.geometry.location.lat(),
    //       lng: selectedPlace.geometry.location.lng(),
    //       country,
    //       state,
    //       city,
    //       country_long,
    //       address: this.locationInp
    //     };

    //     sessionStorage.setItem('CURRENT_SEARCH_LOC', JSON.stringify(currPos));
    //     this.event.setCurrLocationEmit(true);        // this.event.searchEmit();
    //     this.checkAvailableLocation(currPos);
    //   }
    // });
  }

  openMenu(): void {
    this.menuBackDrop = document.querySelector('.menuBackDrop');
    this.menuBackDrop.classList.add('active');
    this.mainMenuWrap = document.querySelector('.mainMenuWrap');
    this.mainMenuWrap.classList.add('active');
  }
  closeMenu(): void {
    this.menuBackDrop = document.querySelector('.menuBackDrop');
    this.menuBackDrop.classList.remove('active');
    this.mainMenuWrap = document.querySelector('.mainMenuWrap');
    this.mainMenuWrap.classList.remove('active');
  }


  sopenMenu(): void {
    this.menuBackDrop = document.querySelector('.smenuBackDrop');
    this.menuBackDrop.classList.add('active');
    this.mainMenuWrap = document.querySelector('.smainMenuWrap');
    this.mainMenuWrap.classList.add('active');
  }
  scloseMenu(): void {
    this.menuBackDrop = document.querySelector('.smenuBackDrop');
    this.menuBackDrop.classList.remove('active');
    this.mainMenuWrap = document.querySelector('.smainMenuWrap');
    this.mainMenuWrap.classList.remove('active');
  }

  openPriceDrop(): void {
    this.profileDrop = document.querySelector('.profileDrop');
    this.profileDrop.classList.toggle('d-block');
  }

  searchWrapOpen(): void {
    this.profileDrop = document.querySelector('.srch');
    this.profileDrop.classList.toggle('d-block');
  }

  getCatergories(): void {
    this.api.get('masterdata/categories').subscribe((res: any) => {
      if (res.status === 200) {
        this.categories = res.data;
      } else {
        this.categories = [];
      }
    }, err => {
      this.categories = 'error';
    });
  }

  getBrands(): void {
    const data = {
      type: 'Featured',
      token: (this.storage.getDataField('token') && this.storage.getDataField('role') === 'user') ? this.storage.getDataField('token') : ''
    };
    this.api.post(`masterdata/brands`, data).subscribe((res: any) => {
      if (res.status === 200) {
        this.brands = res.data;
      } else {
        this.brands = [];
      }
    }, err => {
      this.brands = 'error';
    });
  }

  getProfile(): void {
    this.api.get('user/myprofile').subscribe((res: any) => {
      if (res.status === 200) {
        this.loggedInUser = res.data;
      } else {
        this.loggedInUser = 'N/A';
      }
    }, err => {
      this.loggedInUser = 'N/A';
    });
  }

  gotoProducts(cat: any, subCat?: any): void {
    this.api.selectedCatSubCat = {
      category: cat,
      subCategory: subCat ? subCat : null
    };
    this.scloseMenu();
    if (this.router.url === '/find-products') {
      this.event.setEmitCatSelect(true);
    }
    this.router.navigate(['/find-products']);
  }

  toLogout(): void {
    this.router.navigate(['/home']).then((val: any) => {
      this.storage.clearUser();
      sessionStorage.removeItem('recent-view');
      this.event.setLoginEmmit(false);
    });
  }

  isMerchant(): boolean {
    if (this.storage.getDataField('role') && this.storage.getDataField('role') === 'merchant') {
      return true;
    } else {
      return false;
    }
  }

  // cartCount(): void {
  //   noOfItem
  // }

  doSearch(): void {

    const data = {
      keyword: this.searchText
    };
    this.searching = true;
    this.api.post(`search`, data).subscribe((res: any) => {
      this.searching = false;
      if (res.status === 200) {
        this.seachResult = res.data;
      } else {
        this.seachResult = [];
      }
    }, err => {
      this.searching = false;
      this.seachResult = 'error';
    });
  }

  goToResult(item: any): void {
    if (item?.type === 'Merchant') {
      this.searchText = item?.business_name;
      this.router.navigate(['retailer-details', item?.merchantId, item?._id]);
    } else {
      this.searchText = item?.title;
      this.router.navigate(['product-details', item?._id]);
    }
    this.event.searchEmit();
    // this.doSearch();
  }

  getBannerData() {
    this.api.get('masterdata/static-banner').subscribe((res: any) => {
      if (res.status === 200) {
        this.homePageStatic = res.data;
      }
    })
  }

  search() {
    this.showOpts = false;
    this.router.navigate(['/search', this.searchText])
  }

  checkAvailableLocation(currPos: any): void {
    this.api.post('masterdata/check-city', currPos).subscribe((res: any) => {

      if (res.status === 200) {
        if (!res.data) {
          // this.router.navigate(['maps']);
        }
      } else {
      }
    }, err => {
    });
  }
  storeLocationInp: string = "";
  focusFunction() {
    // const div = document.createElement('div');

    // div.className = 'pac-container pac-logo';
    // div.setAttribute('style', 'width: 326px; position: absolute; left: 715px; top: 57px; display: block');
    // div.innerHTML = `
    // <div class="pac-item">
    //   <span class="pac-icon pac-icon-marker"></span>
    //   <span class="pac-item-query">
    //     <span class="pac-matched" (click)="testLocation()">Current Location</span>
    //   </span>
    // </div>
    // `;
    // document.body.append(div);
    this.storeLocationInp = this.locationInp;
    this.locationInp = "";
  }

  focusOutFunction() {
    let location_input = (document.getElementById('auto') as HTMLInputElement).value;
    this.locationInp = location_input;
    if (this.locationInp == "") {

      this.locationInp = this.storeLocationInp;
    }
    // document.querySelectorAll(".pac-container").forEach(element => {
    //   element.remove();
    // })
  }
  placeDetails(place_id: string) {
    return new Promise((resolve, reject) => {
      let map = new google.maps.Map(document.createElement('div'));
      const service = new google.maps.places.PlacesService(map);
      service.getDetails({ "placeId": place_id }, (place, status) => {
        return resolve(place);
      });
    })
  }
  async onClickLocation(place_id: any, location_name: any) {
    this.type_status = false

    if (place_id != "current_location") {
      //store location history start
      this.storeLocationHistory(place_id, location_name);
      //store location history end

      let selectedPlace: any;
      selectedPlace = await this.placeDetails(place_id);
      let str = '';
      let city: any;
      let state: any;
      let country: any;
      let country_long: any;
      if (selectedPlace) {
        // this.locationInp = str =  selectedPlace.formatted_address;     
        this.locationInp = str = location_name;
        selectedPlace.address_components.forEach((ele: any) => {
          if (ele.types[0] === 'locality') {
            city = ele.long_name;
          }
          if (ele.types[0] === 'administrative_area_level_1') {
            state = ele.long_name;
          }
          if (ele.types[0] === 'country') {
            country = ele.short_name;
            country_long = ele.long_name;
          }

        });
        const currPos = {
          first_load: this.type_status,
          lat: selectedPlace.geometry.location.lat(),
          lng: selectedPlace.geometry.location.lng(),
          country,
          state,
          city,
          country_long,
          address: this.locationInp
        };
        sessionStorage.setItem('CURRENT_SEARCH_LOC', JSON.stringify(currPos));
        this.event.setCurrLocationEmit(true);        // this.event.searchEmit();
        this.checkAvailableLocation(currPos);
      }
    } else if (place_id == "current_location") {
      this.searchByCurrentLocation()
    }

  }
  storeLocationHistory(place_id: any, location_name: any) {
    let searchHistory = localStorage.getItem("locationSearchHistory");
    if (!searchHistory) {
      let loc_obj = [];
      loc_obj.push({
        place_id: place_id,
        location_name: location_name
      })

      localStorage.setItem("locationSearchHistory", JSON.stringify(loc_obj))
    } else if (searchHistory) {
      let history = JSON.parse(searchHistory);

      if (history[0]?.location_name == location_name) {
        //console.log("same");

        return
      }
      if (history.length === 3) {
        history.pop();
        let loc_obj = {
          place_id: place_id,
          location_name: location_name
        }
        history.unshift(loc_obj);
        localStorage.setItem("locationSearchHistory", JSON.stringify(history))
      } else {
        let loc_obj = {
          place_id: place_id,
          location_name: location_name
        }
        history.unshift(loc_obj);
        localStorage.setItem("locationSearchHistory", JSON.stringify(history))
      }
    }
    this.locationHistory();
  }
  redirectToCurrentLocation(){
    // console.log("kkkkkkkkkk");
    
    this.searchByCurrentLocation()
  }

  saveTabType(tabData:any){
    console.log(tabData);
    sessionStorage.setItem('tabeType',tabData)
  }
 

}
