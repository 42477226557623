import { Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { CalendarEvent, CalendarView } from 'angular-calendar';
@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {

  @Input() type: string;
  viewDate: Date;
  availableDate: Date;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Month;
  @Input() data: any;
  @Output() monthSelected = new EventEmitter<string>();
  @Output() yearSelected = new EventEmitter<string>();

  selectedMonth: any;
  canBookThisMonth: boolean = true;
  canBookThisYear: boolean = true;
  monthlyFlagBanner:boolean = true
  leftArrowDisable: boolean = false;
  startOfNextMonth = new Date(moment().add(1, 'M').startOf('month').format('YYYY-MM-DD hh:mm:ss'));
  // selectableMonth: boolean = false;
  monthsArr = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august','september', 'october', 'november', 'december'];
  bookedDate: any;
  typeBanner: boolean = true;
  typeSpotBanner: string;
  constructor() {
  }

  ngOnInit(): void {
    console.log("Souvik->>>>1",this.type);
    console.log("Souvik->>>>2",this.data);
    const thisYear = new Date()?.getFullYear();
    if (this.data) {
      console.log(this.data);
      this.viewDate = new Date(moment(this.data.availableDate, 'DD-MM-YYYY').format());
      //console.log(this.viewDate);
      this.availableDate = new Date(moment(this.data.availableDate, 'DD-MM-YYYY').format());
      //console.log(this.availableDate);
      if (this.data.existing) {
        this.eventsDataRestoration(this.data.existing);
        // this.eventsDataRestorationforYear(this.data.existing);
      }
      const selectedYear = new Date(this.availableDate)?.getFullYear();
      const index = this.data?.existing?.findIndex((element: any) => {
        if(element?.year == selectedYear){ console.log(element);  this.bookedDate = element }
        return element?.year == selectedYear;
      });
      if(index != -1){ 
        this.canBookThisYear = false;
        if(this.bookedDate?.timetype == 'yearly'){ 
          this.typeSpotBanner = 'yearly';
          this.monthlyFlagBanner = false; 
        }else{ 
          this.monthlyFlagBanner = true; 
        }
      }else{
        // this.bookedDate['timetype'] = 'monthly';
         this.canBookThisYear = true; 
         this.monthlyFlagBanner = true;}
    }
    else{
      this.viewDate = new Date(moment( new Date(),'DD-MM-YYYY').format());
    }
    //change month 
    this.refresh.subscribe((res) => {
      // console.log(res , '  ', this.viewDate);
      if (res.type === 'monthChanged') {
        const arr: Array<boolean> = [];
        for (const iterator of this.events) {
          arr.push(moment(this.viewDate).isBetween(moment(iterator.start).format(), moment(iterator.end).format()) || moment(this.viewDate).isSame(moment(iterator.start).format()));
        }
        if (arr.indexOf(true) !== -1) {
          this.canBookThisMonth = false;
        } else {
          this.canBookThisMonth = true;
          // this.isCanBookThisMonth();
        }
        this.isLeftArrowDisable();
        const selectedYear = new Date(this.viewDate)?.getFullYear();
        // console.log(selectedYear);
        const index = this.data?.existing?.findIndex((element: any) => {
          if(element?.year == selectedYear){this.bookedDate = element}
          return element?.year == selectedYear;
        });
        if(index != -1){
          this.canBookThisYear = false;
          if(this.bookedDate?.timetype == 'yearly'){
            this.typeSpotBanner = 'yearly';
            this.monthlyFlagBanner = false;
          }else{ 
            this.monthlyFlagBanner = true;
            this.typeSpotBanner =  'monthly';
          }
        }else{
          this.typeSpotBanner  = 'monthly';
          this.canBookThisYear = true;
          this.monthlyFlagBanner = true;
        }
        // console.log(this.canBookThisYear, selectedYear,this.data?.existing);
        // console.log(this.monthlyFlagBanner , this.canBookThisMonth, this.bookedDate?.timetype);
      }

    });
    this.isLeftArrowDisable();
    //console.log(this.viewDate);
  }

  highLightedMonthOrYear(data: any): boolean {
    if (this.selectedMonth && this.selectedMonth.type == 'month') {
      const date1 = new Date(this.selectedMonth.date);
      const date2 = new Date(data.date);
      return (date1?.getMonth() === date2?.getMonth() && moment().format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) && date1.getFullYear() === date2.getFullYear();
      // return moment().format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD') && date1.getFullYear() === date2.getFullYear();
    } else if (this.selectedMonth && this.selectedMonth.type == 'year') {
      const date1 = new Date(this.selectedMonth.date);
      const date2 = new Date(data.date);
      return date1.getFullYear() === date2.getFullYear();
    } else {
      return false;
    }

  }
  selectThisMonth(viewDate: Date): void {
    const thisMonth = new Date()?.getMonth();
    const selectedMonth = new Date(viewDate)?.getMonth();
    if (thisMonth === selectedMonth) {
      const date = moment(new Date()).format('DD-MM-YYYY');
      this.viewDate = new Date();
    } else {
      const date = new Date(moment(new Date(viewDate)).startOf('month').format('YYYY-MM-DD'));
      this.viewDate = date;
    }
    console.log(this.viewDate);
    if (this.canBookThisMonth) {
      this.selectedMonth = { date: this.viewDate, type: 'month' };
      this.monthSelected.next(this.selectedMonth);
    }
  }

  selectThisYear(viewDate: Date): void {
    const thisYear = new Date().getFullYear();
    console.log('thisYear', thisYear);
    const selectedYear = new Date(viewDate).getFullYear();
    console.log('selectedYear', selectedYear);
    let dates:any
    if (thisYear === selectedYear) {
      const date = moment(new Date()).format('DD-MM-YYYY');
       dates = new Date(moment(new Date(date)).endOf('year').format('YYYY-MM-DD'));
      this.viewDate = new Date();
    } else {
      const date = new Date(moment(new Date(viewDate)).startOf('year').format('YYYY-MM-DD'));
       dates = new Date(moment(new Date(viewDate)).endOf('year').format('YYYY-MM-DD'));
      this.viewDate = date;
    }
    console.log(this.viewDate);

    if (this.canBookThisYear) {
      this.selectedMonth = {
        date: this.viewDate,
        endDate: dates,
        type: 'year'
      };
      this.yearSelected.next(this.selectedMonth);
    }
  }
  dayClicked($event: any): void {
    console.log('$event.day => ',$event.day);
    
    if ($event.day.inMonth == false) {
      return
    }
    if (this.canBookThisMonth) {
      this.selectedMonth = $event.day;
      const thisMonth = new Date()?.getMonth();
      const selectedMonth = new Date($event.day.date)?.getMonth();
      if (thisMonth === selectedMonth) {
        const date = moment(new Date()).format('DD-MM-YYYY');
        this.viewDate = new Date();
      } else {
        const date = new Date(moment(new Date($event.day.date)).startOf('month').format('YYYY-MM-DD'));
        this.viewDate = date;
      }

      // this.monthSelected.next(this.selectedMonth);
      this.selectedMonth = {
        date: this.viewDate
      };
      this.monthSelected.next(this.selectedMonth);
    }
  }

  eventsDataRestoration(data: any): void {

    for (const event of data) {
      // tslint:disable-next-line:max-line-length
      const startDate = new Date(moment(new Date(new Date(new Date().setDate(1)).setMonth(event.month - 1)).setFullYear(event.year)).startOf('day').format());
      const endDate = new Date(moment(startDate).endOf('month').format());
      this.events.push({
        title: '',
        start: new Date(startDate),
        end: new Date(endDate),
      });
    }
  }

  eventsDataRestorationforYear(data: any): void {
    for (const event of data) {
      // tslint:disable-next-line:max-line-length
      const startDate = new Date(moment(new Date().setFullYear(event.year)).startOf('year').format());
      const endDate = new Date(moment(startDate).endOf('year').format());
      console.log(startDate);
      console.log(endDate);


      this.events.push({
        title: '',
        start: new Date(startDate),
        end: new Date(endDate),
      });
    }
  }

  beforeMonthViewRender(event: any, el: Element): void {
    const arr: Array<boolean> = [];
    for (const iterator of this.events) {
      // tslint:disable-next-line:max-line-length
      arr.push(moment(this.viewDate).isBetween(moment(iterator.start).format(), moment(iterator.end).format()) || moment(this.viewDate).isSame(moment(iterator.start).format()));
    }
    if (arr.indexOf(true) !== -1) {
      // el.classList.add('disabled');
      this.canBookThisMonth = false;
    } else {
      // el.classList.remove('disabled');
      this.canBookThisMonth = true;
      // this.isCanBookThisMonth();
    }
  }

  isLeftArrowDisable(): any {
    let availableDate = this.availableDate?.getMonth() + 1
    let viewMonth = this.viewDate?.getMonth() + 1;
    let viewYear = this.viewDate.getFullYear();
    let nextMonth = this.startOfNextMonth?.getMonth() + 1;
    let nextYear = this.startOfNextMonth.getFullYear();
    // if ( viewYear <= nextYear && viewMonth <= nextMonth) {
    //   // return true;
    //   this.leftArrowDisable = true;
    // }else {
    //   // return false;
    //   this.leftArrowDisable = false;
    // }
    // ( viewYear < nextYear) || (viewYear === nextYear && viewMonth <= nextMonth)
    // ( viewYear < nextYear) || (viewMonth <= availableDate)
    if ((viewYear < nextYear) || (viewYear === nextYear && viewMonth <= availableDate)) {
      this.leftArrowDisable = true;
    } else {
      this.leftArrowDisable = false;
    }
  }
  isCanBookThisMonth(): void {
    // let viewMonth = this.viewDate.getMonth() + 1;
    // let viewYear = this.viewDate.getFullYear();
    // let nextMonth = this.startOfNextMonth.getMonth() + 1;
    // let nextYear = this.startOfNextMonth.getFullYear();
    // if (( viewYear < nextYear) || (viewYear === nextYear && viewMonth < nextMonth)) {
    //   // this.canBookThisMonth = false;
    // } else {
    //   // this.canBookThisMonth = true;
    // }
    let availableDate = this.availableDate?.getMonth() + 1
    let viewMonth = this.viewDate?.getMonth() + 1;
    let viewYear = this.viewDate.getFullYear();
    let nextMonth = this.startOfNextMonth?.getMonth() + 1;
    let nextYear = this.startOfNextMonth.getFullYear();
    // if ( viewYear <= nextYear && viewMonth <= nextMonth) {
    //   // return true;
    //   this.leftArrowDisable = true;
    // }else {
    //   // return false;
    //   this.leftArrowDisable = false;
    // }
    // ( viewYear < nextYear) || (viewYear === nextYear && viewMonth <= nextMonth)
    // ( viewYear < nextYear) || (viewMonth <= availableDate)
    if ((viewYear < nextYear) || (viewYear === nextYear && viewMonth < availableDate)) {
      this.canBookThisMonth = false;
    } else {
      this.canBookThisMonth = true;
    }

  }

}
