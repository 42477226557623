import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  paymentForm: FormGroup;
  disableButton = false;

  itemPrice: any;
  couponCode:any
  appliedCoupon: any;
  preFilledData: any;
  tax = 0;
  sc = 0;
  totalPrice = 0;
  payableAmount = 0;
  priceBreak: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
  public dialogRef: MatDialogRef<ConfirmationComponent>, private fb: FormBuilder, private api: ApiService) {
    dialogRef.disableClose = true;
    this.router.events.subscribe(() => {
      dialogRef.close();
    });
   }

  ngOnInit(): void {
    console.log(this.data);
 
    
    if(this.data && this.data.itemCost){
      this.itemPrice = parseFloat(this.data.itemCost);
      console.log(this.itemPrice);
    }
    if(this.data && this.data.tax){
      this.tax = parseFloat(this.data.tax);
    }
    if(this.data && this.data.sc){
      this.sc = parseFloat(this.data.sc);
    }
    this.totalPrice = this.itemPrice + this.tax + this.sc;
    this.payableAmount = this.totalPrice;
      console.log(this.totalPrice);


    if (this.data?.preFillerData) {
      this.preFilledData = this.data?.preFillerData;
      this.couponCode = this.preFilledData?.code;
      if (this.couponCode) {
        this.applyCoupon();
      }
    }
    this.formInit();
    if (this.data?.itemType === 'Pin') {
      this.priceBreak = this.data?.priceBreak;
    }
  }

  formInit(){
    this.paymentForm = this.fb.group({
      full_name: ['', [Validators.required, Validators.pattern(/^[^\s-]/)]],
      card_number: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(16)]],
      exp_month: ['', [Validators.required, Validators.minLength(2)]],
      exp_year: ['', [Validators.required, Validators.minLength(4)]],
      cvv: ['', [Validators.required, Validators.minLength(3)]],
      address: ['', [Validators.required, Validators.pattern(/^[^\s-]/)]]
    })
    if (this.preFilledData) {
      this.paymentForm.patchValue({
        full_name: this.preFilledData?.full_name,
        card_number: this.preFilledData?.card_number,
        exp_month: this.preFilledData?.exp_month,
        exp_year: this.preFilledData?.exp_year,
        cvv: this.preFilledData?.cvv,
        address: this.preFilledData?.address,
      })
    }
  }

  applyCoupon(){
    this.appliedCoupon = undefined;  
    if(this.couponCode && this.couponCode.length){
      const obj = {
        code: this.couponCode
      }
      this.api.post('promocode/check', obj).subscribe((res:any) => {
        
        this.itemPrice = this.data.itemCost
        if(res.status === 200){
          this.appliedCoupon = res.data;
          if(res.data.discountType === 'fixed'){
            // this.itemPrice = parseFloat(this.itemPrice) - parseFloat(res.data.discountAmount);
            this.payableAmount = this.totalPrice - parseFloat(res.data.discountAmount);
          }else if(res.data.discountType === 'percentage'){
            let fixedOff = 1 - (parseFloat(res.data.discountAmount)/100);
            this.payableAmount = parseFloat((this.totalPrice*fixedOff).toFixed(2));
          }
          this.api.alert(res.message, 'success')
        }else{
          this.itemPrice = this.data.itemCost
          this.api.alert(res.message, 'warning')
        }
      }, err => {
        this.itemPrice = this.data.itemCost
        this.api.alert('Some error occurred', 'error');
      })
    }
  }

  getTotalAmt(){
    let s = 0;
    s += parseFloat(this.itemPrice);
    if(this.data.sc){
      s += parseFloat(this.data.sc);
    }
    if(this.data.tax){
      s+= parseFloat(this.data.tax);
    }
    return s
  }

  confirm(){
    this.dialogRef.close(true);
  }

  closeDialog(){
    this.dialogRef.close();
  }

  getPlace(inputId: string): void {

    const input = document.getElementById(inputId) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: ['US','CA'] },
      // componentRestrictions: { country: 'US', postalCode: 75001 }, // to restrict the search in a country
      // types: ['street_address']  // 'establishment' / 'address' / 'geocode' / 'street_address'
    };
    const place = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(place, 'place_changed', () => {
      let selectedPlace: any;
      selectedPlace = place.getPlace();
      let str = '';
      if(selectedPlace){
        str = selectedPlace.formatted_address;
        this.paymentForm.patchValue({
          address: str
        })
      }
    });
  }

  goBack(){
    this.dialogRef.close('back')
    
  }

  parseFloat(value: any): any {
    return parseFloat(value).toFixed(2);
  }

}

