<div class="calender " #monthView>
  <!-- <pre>{{ data | json }}</pre> -->
  <ng-container *ngIf="type === 'spotBooking'">
    <div class="calender_main_header">
      <div class="top_most_header">
        <div class="action_icon_button ">
          <!-- prev disable -->
          <!-- *ngIf="!leftArrowDisable" -->
          <button [ngClass]="leftArrowDisable ? 'prev' : 'prev'" type="button" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" (mwlClick)="refresh.next({type: 'monthChanged', el: monthView})"
            [class.disabled]="leftArrowDisable" *ngIf="!leftArrowDisable">
            <mat-icon class="mat-18">keyboard_arrow_left</mat-icon>
          </button>
          <button [ngClass]="leftArrowDisable ? 'prev' : 'prev'" type="button" [class.disabled]="leftArrowDisable"
            *ngIf="leftArrowDisable" [disabled]="true">
            <mat-icon class="mat-18">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="viewDate">{{viewDate | date: 'MMMM, yyyy'}}</div>
        <div class="action_icon_button ">
          <button class="next" type="button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (mwlClick)="refresh.next({type: 'monthChanged', el: monthView})">
            <mat-icon class="mat-18">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
      <ng-template #header let-days="days" let-locale="locale">
        <div class="days">
          <ng-container *ngFor="let day of days">  <div class="day">{{day?.date | date: 'EE'}}</div>  </ng-container>
        </div>
      </ng-template>
    </div>

    <ng-template #cellTemplate let-day="day" let-locale="locale">
      <ng-container *ngIf="data?.type == 'banner'">
        <ng-container *ngIf="typeSpotBanner == 'yearly'">
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inYear"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        </ng-container>
        <ng-container *ngIf="typeSpotBanner != 'yearly'">
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inMonth"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="data?.type == 'spot'">
        <ng-container *ngIf="typeSpotBanner == 'yearly'">
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inYear"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        </ng-container>
        <ng-container *ngIf="typeSpotBanner != 'yearly'">
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inMonth"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="data?.type != 'banner' && data?.type != 'spot'">
        <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inMonth"
        [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
        <div class="date_box">
          <span class="date_box_date"> {{day.date | date: 'd'}}</span>
        </div>
      </div>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="data?.type == 'banner'">
      <ng-container *ngIf="typeSpotBanner == 'yearly'">
        <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh" [headerTemplate]="header"
        [cellTemplate]="cellTemplate" >
      </mwl-calendar-month-view>
      </ng-container>
      <ng-container *ngIf="typeSpotBanner != 'yearly'">
        <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh" [headerTemplate]="header"
        [cellTemplate]="cellTemplate" (dayClicked)="dayClicked($event)"
        (beforeViewRender)="beforeMonthViewRender($event, monthView)">
      </mwl-calendar-month-view>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data?.type == 'spot'">
      <ng-container *ngIf="typeSpotBanner == 'yearly'">
        <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh" [headerTemplate]="header"
        [cellTemplate]="cellTemplate" >
      </mwl-calendar-month-view>
      </ng-container>
      <ng-container *ngIf="typeSpotBanner != 'yearly'">
        <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh" [headerTemplate]="header"
        [cellTemplate]="cellTemplate" (dayClicked)="dayClicked($event)"
        (beforeViewRender)="beforeMonthViewRender($event, monthView)">
      </mwl-calendar-month-view>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data?.type != 'spot' && data?.type != 'banner'">
      <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh" [headerTemplate]="header"
        [cellTemplate]="cellTemplate" (dayClicked)="dayClicked($event)"
        (beforeViewRender)="beforeMonthViewRender($event, monthView)">
      </mwl-calendar-month-view>
    </ng-container>


  </ng-container>
</div>
<div class="calendermainbutton">
  <ng-container *ngIf="data?.type == 'spot'">
    
    <div class="calender_action">
      <!-- || leftArrowDisable -->
      <ng-container *ngIf="typeSpotBanner == 'yearly'">
        <button [class.disabled]="!monthlyFlagBanner" [disabled]="!monthlyFlagBanner" class="btn_select_month"
          type="button" (click)="selectThisMonth(viewDate)">Select this month</button>
      </ng-container>
      <ng-container *ngIf="typeSpotBanner != 'yearly'">
        <button [class.disabled]="!canBookThisMonth" [disabled]="!canBookThisMonth" class="btn_select_month"
          type="button" (click)="selectThisMonth(viewDate)">Select this month</button>
      </ng-container>
    </div>
    <div class="calender_action calenderbuttonone">
      <button [class.disabled]="!canBookThisYear" [disabled]="!canBookThisYear" class="btn_select_month" type="button"
        (click)="selectThisYear(viewDate)">Select this year</button>
      <p *ngIf="!canBookThisYear"> Allready booked {{monthsArr[bookedDate?.month -1]}} {{bookedDate?.year}}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="data?.type == 'banner'">
    <div class="calender_action">
      <!-- || leftArrowDisable -->
      <ng-container *ngIf="typeSpotBanner == 'yearly'">
        <button [class.disabled]="!monthlyFlagBanner" [disabled]="!monthlyFlagBanner" class="btn_select_month"
          type="button" (click)="selectThisMonth(viewDate)">Select this month</button>
      </ng-container>
      <ng-container *ngIf="typeSpotBanner != 'yearly'">
        <button [class.disabled]="!canBookThisMonth" [disabled]="!canBookThisMonth" class="btn_select_month"
          type="button" (click)="selectThisMonth(viewDate)">Select this month</button>
      </ng-container>
    </div>
    <div class="calender_action calenderbuttonone">
      <button [class.disabled]="!canBookThisYear" [disabled]="!canBookThisYear" class="btn_select_month" type="button"
        (click)="selectThisYear(viewDate)">Select this year</button>
    </div>

  </ng-container>


  <div class="calender_action calenderbuttonone" *ngIf="data?.type == 'location'">
    <div class="calender_action">
      <!-- || leftArrowDisable -->
      <button [class.disabled]="!canBookThisMonth" [disabled]="!canBookThisMonth" class="btn_select_month" type="button"
        (click)="selectThisMonth(viewDate)">Select this month</button>
    </div>
    <button [class.disabled]="!canBookThisYear" [disabled]="!canBookThisYear" class="btn_select_month" type="button"
      (click)="selectThisYear(viewDate)">Select this year</button>
  </div>
</div>









<!-- calender header -->
<ng-container *ngIf="type === 'sidepannel'">
    <div class="calender_main_header">
      <div class="top_most_header">
        <div class="action_icon_button ">
          <!-- prev disable -->
          <!-- *ngIf="!leftArrowDisable" -->
          <button [ngClass]="leftArrowDisable ? 'prev' : 'prev'" type="button" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" (mwlClick)="refresh.next({type: 'monthChanged', el: monthView})"
            [class.disabled]="leftArrowDisable" *ngIf="!leftArrowDisable">
            <mat-icon class="mat-18">keyboard_arrow_left</mat-icon>
          </button>
          <button [ngClass]="leftArrowDisable ? 'prev' : 'prev'" type="button" [class.disabled]="leftArrowDisable"
            *ngIf="leftArrowDisable" [disabled]="true">
            <mat-icon class="mat-18">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="viewDate">{{viewDate | date: 'MMMM, yyyy'}}</div>
        <div class="action_icon_button ">
          <button class="next" type="button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (mwlClick)="refresh.next({type: 'monthChanged', el: monthView})">
            <mat-icon class="mat-18">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
      <ng-template #header let-days="days" let-locale="locale">
        <div class="days">
          <ng-container *ngFor="let day of days"> <div class="day">{{day?.date | date: 'EE'}}</div> </ng-container>
        </div>
      </ng-template>
    </div>

    <ng-template let-day="day" let-locale="locale">
      <!-- <ng-container *ngIf="data?.type == 'banner'"> -->
        <!-- <ng-container *ngIf="typeSpotBanner == 'yearly'"> -->
          <!-- <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inYear"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0"> -->
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        <!-- </div> -->
        <!-- </ng-container> -->
        <!-- <ng-container *ngIf="typeSpotBanner != 'yearly'"> -->
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inMonth"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        <!-- </ng-container> -->
      <!-- </ng-container> -->
      <!-- <ng-container *ngIf="data?.type == 'spot'">
        <ng-container *ngIf="typeSpotBanner == 'yearly'">
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inYear"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        </ng-container>
        <ng-container *ngIf="typeSpotBanner != 'yearly'">
          <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inMonth"
          [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
          <div class="date_box">
            <span class="date_box_date"> {{day.date | date: 'd'}}</span>
          </div>
        </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="data?.type != 'banner' && data?.type != 'spot'">
        <div class="cal-cell-top day_box" [class.highlight]="highLightedMonthOrYear(day)" [class.inMonth]="!day?.inMonth"
        [class.isFuture]="day?.isFuture" [class.isPast]="day?.isPast" [class.disabled]="day?.events?.length > 0">
        <div class="date_box">
          <span class="date_box_date"> {{day.date | date: 'd'}}</span>
        </div>
      </div>
      </ng-container> -->
    <!-- </ng-template> -->
<!-- </ng-container> -->


