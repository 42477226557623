import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-free-pin-drop',
  templateUrl: './free-pin-drop.component.html',
  styleUrls: ['./free-pin-drop.component.scss']
})
export class FreePinDropComponent implements OnInit {
  selectedLoc: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<FreePinDropComponent>,
  private api: ApiService) { }

  ngOnInit(): void {
  }

  getPlace(inputId: string): void {

    const input = document.getElementById(inputId) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: ['US','CA'] },
      // componentRestrictions: { country: 'US', postalCode: 75001 }, // to restrict the search in a country
      // types: ['street_address']  // 'establishment' / 'address' / 'geocode' / 'street_address'
    };
    const place = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(place, 'place_changed', () => {
      let selectedPlace: any;
      selectedPlace = place.getPlace();
      let city: any;
      let state: any;
      let country: any;
      if(selectedPlace){
        selectedPlace.address_components.forEach((ele:any) => {
          if(ele.types[0]=== 'locality'){
            city = ele.long_name
          }
          if(ele.types[0] === 'administrative_area_level_1'){
            state = ele.long_name
          }
          if(ele.types[0]=== 'country'){
            country= ele.short_name
          }
        })
        const currPos = {
          lat: selectedPlace.geometry.location.lat(), 
          lng: selectedPlace.geometry.location.lng(), 
          country: country,
          state: state,
          city: city,
          address: selectedPlace.formatted_address
        };
        this.selectedLoc = currPos;
      }
    });
  }

  submit(){
    if(this.selectedLoc){
      let dataset  = {
        paymentId: this.data.paymentId,
        lat: this.selectedLoc.lat, 
        long: this.selectedLoc.lng, 
        country: this.selectedLoc.country,
        state: this.selectedLoc.state,
        city: this.selectedLoc.city,
        address: this.selectedLoc.address
      }
      this.api.post('payment/pin', dataset).subscribe((res:any) =>{
        if(res.status === 200){
          this.api.alert('You got a free pin drop', 'success');
          this.dialogRef.close();
        }else{
          this.api.alert(res.message, 'warning');
        }
      }, err => {
        this.api.alert('Something went wrong', 'error');
      })
    }else{
      this.api.alert('Please select a location', 'warning')
    }
  }

}
