import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {


  @Input() color = '#ffffff';
  @Input() class: string;
  @Input() background = 'transparent';
  @Input() fullPage = false;
  @Input() text = ''; //Wait
  @Input() isButton = true;
  @Input() buttonText = '';


  loading: boolean;
  customLoading: boolean;
  isLoading: any;

  constructor(
    private event: EventService,
    private cdrf: ChangeDetectorRef
  ) {
    this.isLoading = this.event.isLoading;
    this.loading = true;
  }

  ngOnInit(): void {

    this.event.isLoading.subscribe((res: boolean) => {
      this.loading = res;
    });

    this.event.isLoading.subscribe((res: boolean) => {
      this.customLoading = res;
      this.cdrf.detectChanges();
    });

  }
}
