import { Directive, ElementRef, Input, AfterViewInit, HostListener, OnChanges } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[isDisable]'
})
export class DisabledDirective implements AfterViewInit, OnChanges {

  @Input('isDisable') isDisabled: boolean;

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('document:mousemove', ['$event'])
  private onMouseMove(e: any): void {
    this.isDisabledInit(this.isDisabled);
  }

  @HostListener('scroll', ['$event']) private onScroll($event: any): void {
    this.isDisabledInit(this.isDisabled);
  }

  ngOnChanges(): void {
    this.isDisabledInit(this.isDisabled);
  }
  ngAfterViewInit(): void {
    this.isDisabledInit(this.isDisabled);
  }

  isDisabledInit(isDisabled: boolean): void {
    console.log('ljfdjdfik');
    
    if (this.el.nativeElement.nodeName !== 'INPUT') {
      if (isDisabled) {
        this.el.nativeElement.classList.add('disabled');
        this.el.nativeElement.style.opacity = '.59';
        this.el.nativeElement.style.pointerEvents = 'none';
      } else {
        this.el.nativeElement.style.opacity = '1';
        this.el.nativeElement.classList.remove('disabled');
        this.el.nativeElement.style.pointerEvents = 'auto';
      }
    } else {
      if (isDisabled) {
        this.el.nativeElement.setAttribute('readonly', true);
      } else {
        this.el.nativeElement.removeAttribute('readonly');
      }
    }
  }
}
