import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-age-verified',
  templateUrl: './age-verified.component.html',
  styleUrls: ['./age-verified.component.scss']
})
export class AgeVerifiedComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<AgeVerifiedComponent>) { }

  ngOnInit(): void {
  }

submit(){
  this.dialogRef.close(true)
}
cancel(){
  this.dialogRef.close(false)
}

}
