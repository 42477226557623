import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutocompleteComponent } from '../address/google-places/google-places.component';



@NgModule({
  declarations: [AutocompleteComponent],
  imports: [CommonModule,
    FormsModule],
  exports: [AutocompleteComponent],
  entryComponents: [AutocompleteComponent],
})
export class AddressModule {
  static rootComponent = AutocompleteComponent
}
