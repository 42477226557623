<!-- Footer Top -->
<div class="container-fluid" *ngIf="footerData">
  <div class="row">
    <div class="footerTop">
      <!-- src="./assets/images/footerTop.png" -->
      <img [src]="BASEURL + 'homepage_banner/' + homePageStatic?.banner" alt="" class="img-fluid">
      <div class="footerTopOverlap">
        <div class="container" >
          <div class="row">
            <div class="col-lg-6">
              <!-- <h2>{{homePageStatic?.home_banner2_small_text}} <br/><span>
                                {{homePageStatic?.home_banner2_large_text}}
                            </span></h2> -->
              <div class="footerTxtDynamic" *ngIf="homePageStatic">
                <span class="py-3 small-one">{{homePageStatic?.small_text?.text}}</span>
                <span class="d-block me-one">{{homePageStatic?.medium_text?.text}}</span>
                <span class="large-one">{{homePageStatic?.large_text?.text}}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex align-items-center h-100" *ngIf="!isMerchantLoggedIn()">
                <button class="btn btn-primary rounded-pill py-lg-4 px-lg-5 p-auto mr-3"
                  routerLink="/login-merchant">Merchant Login</button>
                <button class="btn btn-light rounded-pill py-lg-4 px-lg-5 p-auto"
                  routerLink="/register-merchant">Merchant Signup</button>
              </div>

              <div class="d-flex align-items-center h-100" *ngIf="isMerchantLoggedIn()">
                <button class="btn btn-primary rounded-pill py-lg-4 px-lg-5 p-auto mr-3"
                  routerLink="/merchant-dashboard">Merchant Dashboard</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<footer>
  <div class="container py-lg-5 py-3" *ngIf="!authenticated">
    <div class="row">
      <div class="col-lg-4 col-12">
        <img src="./assets/images/logo.svg" alt="" routerLink="/">
        <p class="px-2 mb-0">Download the app</p>
        <div class="row mx-0">
          <div class="col-12 px-0">
            <a [href]="settingsData['playstore']"><img src="./assets/images/playstore.svg" alt=""></a>
            <a [href]="settingsData['apple_store']"><img src="./assets/images/appstore.svg" alt=""></a>
          </div>
          <div class="col-12 px-0 pt-lg-5 pt-0">
            <a [href]="settingsData['twitter']" class="mr-3 ml-2"><img src="./assets/images/twitter.svg" alt=""></a>
            <a [href]="settingsData['facebook']" class="mr-3 ml-2"><img src="./assets/images/facebook.svg" alt=""></a>
            <a [href]="settingsData['instragram']" class="mr-3 ml-2"><img src="./assets/images/insta.svg" alt=""></a>
            <a [href]="settingsData['youtube']" class="mr-3 ml-2"><img src="./assets/images/youtube.svg" alt=""></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-wrap justify-content-center col-6 mt-lg-0 mt-5">
        <div>
          <h6 class="mb-lg-4 mb-3">About</h6>
          <ul>
            <li><a [routerLink]="['/about', 'company']">Company </a></li>
            <!-- <li><a [routerLink]="['/about', 'careers']">Careers </a></li> -->
            <li><a [routerLink]="['/about', 'help-center']">Help center </a></li>
            <li><a [routerLink]="['/about', 'advocacy']">Advocacy </a></li>
            <li><a [routerLink]="['/register-merchant']">Add Business</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-wrap justify-content-center col-6 mt-lg-0 mt-5">
        <div>
          <h6 class="mb-lg-4 mb-3">Legal</h6>
          <ul>
            <li><a [routerLink]="['/legal', 'terms-and-use']">Terms of use </a></li>
            <li><a [routerLink]="['/legal', 'privacy-policy']">Privacy policy </a></li>
            <li><a [routerLink]="['/legal', 'cookie-policy']">Cookie policy </a></li>
            <!-- <li><a [routerLink]="['/legal', 'referral-program']">Referral program </a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copyr">
    <p>Copyright &copy; {{currentYear}} <a routerLink="/">Kushmapz.com</a></p>
  </div>

</footer>
