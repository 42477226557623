 
<!-- <div class="modalwrap scroll-modal paymentModal" cdkDrag > -->
  <div class="modalwrap scroll-modal paymentModal">
  <div class="row flex-lg-row flex-md-column-reverse flex-sm-column-reverse">
    <div class="col-lg-7">
      <ng-container *ngIf="data?.typeMode == 'year'">
        <div class="row" *ngIf="universalPromotions && universalPromotions !== 'error' && universalPromotions.length > 0">
          <!-- py-lg-5 py-3  -->
          <div class="sad">
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
                  <div ngxSlickItem *ngFor="let item  of universalPromotions; let i = index;">
                      <div class="mr-lg-3 mr-0 promotion-holder">
                          <div class="slide">
                              <img src="{{BASEURL}}promotion/{{ item?.image }}" alt="" onerror="this.src = './assets/images/rgisterotherimg.png'" class="img-bg">
                              <div class="text_promoCode">
                                  <p>{{item?.type | titlecase }}</p>
                                  <p *ngIf="item?.discountType === 'percentage'"><span>USE PROMO CODE AND SAVE </span>{{item?.discountAmount}}%</p>
                                  <p *ngIf="item?.discountType !== 'percentage'"><span>USE PROMO CODE AND SAVE </span>${{item?.discountAmount}}</p>
                                  <h1> <span>CODE : </span>{{item?.code}}</h1>
                              </div>
                          </div>
                      </div>
                  </div>
              </ngx-slick-carousel>
              <!-- <ng-container *ngFor="let item  of universalPromotions; let i = index;">

                  <div class="mr-lg-3 mr-0 promotion-holder" *ngIf="i % 2 === 0">
                      <div class="slide">
                          <img src="{{BASEURL}}promotion/{{ item?.image }}" alt="" onerror="this.src = './assets/images/no-image.png'" class="img-bg">
                          <div class="text_promoCode">
                              <p>{{item?.type | titlecase }}</p>
                              <p *ngIf="item?.discountType === 'percentage'">{{item?.discountAmount}}% OFF</p>
                              <p *ngIf="item?.discountType !== 'percentage'">${{item?.discountAmount}} OFF</p>
                              <h1>
                                  <span>CODE : </span>{{item?.code}}
                              </h1>
                          </div>
                      </div>
                  </div>
              </ng-container> -->

          </div>
      </div>
      </ng-container>
      <div class="headingCard">
        <h4>Enter your card details</h4>
        <img src="assets/images/card.png" alt="" width="150" class="ml-auto d-block">
      </div>
      <div class="formsec" [formGroup]="paymentForm">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input placeholder="Full Name" formControlName="full_name" autocomplete="off" [readonly]="data?.isRegisterPatchUser">
              <mat-error
                *ngIf=" (paymentForm.controls.full_name.touched && paymentForm.controls.full_name.hasError('required'))">
                Card holder Name is required.
              </mat-error>
              

              <mat-error
                *ngIf="paymentForm.controls.full_name.touched && paymentForm.controls.full_name.hasError('pattern')">
                Card holder Name consists of white spaces.
              </mat-error>
            </div>
          </div>
          <div class="col-12">
            <!-- <div class="form-group autocomp"> -->
            <div class="form-group">
              <input placeholder="Address" formControlName="address" (input)="getPlace('auto')" id="auto" 
                 class="pac-target-input" [readonly]="data?.isRegisterPatchUser" >
                 <!-- (click)="clearAddress('auto')" -->
              <!-- <input AutocompleteComponent placeholder="Address" formControlName="address" id="auto" autocomplete="off" class="pac-target-input" (setAddress)="getAddress($event)" adressType="geocode"> -->
              <!-- <AutocompleteComponent (setAddress)="getAddress($event)" formControlName="address" id="auto" adressType="geocode"></AutocompleteComponent> -->
              <mat-error
                *ngIf="paymentForm.controls.address.touched && paymentForm.controls.address.hasError('required')">
                Card holder Address is required.
              </mat-error>

              <mat-error
                *ngIf="paymentForm.controls.address.touched && paymentForm.controls.address.hasError('pattern')">
                Card holder Address consists of white spaces.
              </mat-error>
            </div>
          </div>

          <div class="col-md-6">
            <div class="from-group">
                <input type="text" placeholder="City" readonly formControlName="City"
                readonly
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')">
                <!-- <div class="error"
                    *ngIf="freeMemberLogin.controls.City.touched && freeMemberLogin.controls.City.hasError('required')">
                    This is mandatory.
                </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" placeholder="State" readonly formControlName="State"
              readonly 
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')">
            <!-- <div class="error"
                *ngIf="freeMemberLogin.controls.State.touched && freeMemberLogin.controls.State.hasError('required')">
                This is mandatory.
            </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" placeholder="Zip" readonly formControlName="Zip"
              readonly
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')">
          <!-- <div class="error"
              *ngIf="freeMemberLogin.controls.Zip.touched && freeMemberLogin.controls.Zip.hasError('required')">
              This is mandatory.
          </div> -->
          
          <!-- <div class="error"
              *ngIf="freeMemberLogin?.get('Zip')?.touched && freeMemberLogin?.get('Zip')?.value && freeMemberLogin?.get('Zip')?.invalid">
              Postal Code should be alphanumeric or numeric only</div> -->
            </div>
           

          </div>

          <div class="col-12">
            <div class="form-group">
              <input type="text" [mask]="cardMask" placeholder="Card Number" formControlName="card_number" autocomplete="off">
              <mat-error
                *ngIf="paymentForm.controls.card_number.touched && paymentForm.controls.card_number.hasError('required')">
                Card number is required.
              </mat-error>

              <mat-error
                *ngIf="paymentForm.controls.card_number.touched && paymentForm.controls.card_number.hasError('minlength')">
                Card number should be of minimum 12 digits
              </mat-error>

              <mat-error
                *ngIf="paymentForm.controls.card_number.touched && paymentForm.controls.card_number.hasError('maxlength')">
                Card number should be of maximum 16 digits
              </mat-error>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <select placeholder="Expiry Year" class="ng-untouched ng-pristine ng-valid" formControlName="exp_year"
                (change)="checkValidity('year')">
                <option value=''>select year</option>
                <option [value]="year" *ngFor="let year of yearArray"
                  [disabled]="parseInt(paymentForm.controls.exp_month.value) < currentMonth && year === currentYear">
                  {{year}}</option>
              </select>
              <mat-error
                *ngIf="paymentForm.controls.exp_year.touched && paymentForm.controls.exp_year.hasError('required')">
                Year is required.
              </mat-error>
              <mat-error
                *ngIf="paymentForm.controls.exp_year.touched && paymentForm.controls.exp_year.hasError('minlength')">
                Year must be YYYY format.
              </mat-error>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <select placeholder="Expiry month (MM)" class="ng-untouched ng-pristine ng-valid"
                formControlName="exp_month" (change)="checkValidity('month')">
                <option value=''>select month</option>
                <option [value]="month" *ngFor="let month of monthArray"
                  [disabled]="parseInt(paymentForm.controls.exp_year.value) === currentYear && parseInt(month) < currentMonth">
                  {{month}}</option>
              </select>
              <mat-error
                *ngIf="paymentForm.controls.exp_month.touched && paymentForm.controls.exp_month.hasError('required')">
                Month is required.
              </mat-error>
              <mat-error
                *ngIf="paymentForm.controls.exp_month.touched && paymentForm.controls.exp_month.hasError('minlength')">
                Month must be MM format.
              </mat-error>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <input type="password" formControlName="cvv" mask="000" placeholder="CVV" autocomplete="off">
              <mat-error *ngIf="paymentForm.controls.cvv.touched && paymentForm.controls.cvv.hasError('required')">
                CVV is required.
              </mat-error>

              <mat-error *ngIf="paymentForm.controls.cvv.touched && paymentForm.controls.cvv.hasError('minlength')">
                CVV must be of 3 digits.
              </mat-error>
            </div>
          </div>
    
        </div>
      </div>

    </div>
    <div class="col-lg-5 mb-3">
      <div class="paymentright">
        <div class="couponCode">
          <div class="form-group">
            <input placeholder="Enter Coupon Code" [(ngModel)]="couponCode" autocomplete="off" id="couponInput">
            <button class="greenbtn" (click)="applyCoupon()">Apply</button>
          </div>
          <div *ngIf="appliedCoupon" style="text-align: left;" >
            <p class="coupon" style="margin: 0;text-align: left;color: #49BB80;" >Coupon Applied  
              <span><img (click)="deleteCoupon()"  src="../../../assets/images/delete-button.png" alt=""></span></p>
          </div>
        </div>
        <div class="tableWrap">
          <table>
            <tbody>

              <tr>
                <td>Amount:</td>
                <td><span>${{data?.itemCost}}</span></td>
              </tr>
              <tr>
                <td>Service Charge:</td>
                <td><span>${{sc}}</span></td>
              </tr>
              <tr>
                <td>Tax:</td>
                <td><span>${{tax}}</span></td>
              </tr>
              <tr >
                <td>Total Amount:</td>
                <td><span>${{totalPrice.toFixed(2)}}</span></td>
              </tr>
              <!--  -->

              <tr *ngIf="appliedCoupon">
                <td>Discount:</td>
                <td *ngIf="appliedCoupon.discountType === 'month'"> <span>{{appliedCoupon?.description}}</span></td>
                <ng-container *ngIf="appliedCoupon.discountType !== 'month'">
                  <td *ngIf="appliedCoupon.discountType === 'fixed'"> <span>${{appliedCoupon?.discountAmount}}</span>
                  </td>
                  <td *ngIf="appliedCoupon.discountType === 'percentage'">
                    <span>{{appliedCoupon?.discountAmount}}%</span></td>
                </ng-container>
              </tr>
              <!--  -->
              <tr *ngIf="data && data?.itemCost">
                <td>Payable Amount:</td>
                <td> <span>${{payableAmount.toFixed(2)}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="detailpayment" *ngIf="data?.itemType && data?.location && data?.timePeriod">
          <ul>
            <li *ngIf="data?.itemType"><span>Description:</span> {{data?.itemType}}</li>
            <!-- <li *ngIf="data?.timePeriod"><span>Duration:</span> {{data?.timePeriod}}</li> -->
            <li *ngIf="data?.location"><span>Address:</span> {{data?.location}}</li>
            <li *ngIf="data?.seconderylocation"><span>Second Location:</span> {{data?.seconderylocation}}</li>
          </ul>
        </div>
      </div>
      <div class="col-12 buttonWrap">
        <ul>
          <li><button class="cancelbtn btn-danger" (click)="closeDialog()"
              >Cancel</button></li>
          <li *ngIf="data?.backBtn"><button class="cancelbtn" (click)="goBack()"
             >Back</button></li>
          <li> <button class="greenbtn" (click)="submitPaymentDetails()" [disabled]="disableButton">Place your order</button>
          </li>
        </ul>

      </div>
    </div>

  </div>

  <!-- <div class="container"> -->
  <!-- <div class="paymentTop">
            <div class="row">
                <div class="col-md-3" *ngIf="data && data?.itemCost">
                    <h5>Amount: <span class="font-weight-bold">${{data?.itemCost}}</span></h5>
                </div>
                <div class="col-md-6" *ngIf="data && data?.sc">
                    <h5 class="d-block text-center">Service Charge: <span class="font-weight-bold">${{data?.sc}}</span></h5>
                </div>
                <div class="col-md-3 text-right" *ngIf="data && data?.tax">
                    <h5 class="d-block text-right">Tax: <span class="font-weight-bold">${{data?.tax}}</span></h5>
                </div>
            </div>
         </div>
        <div class="row" *ngIf="data && data?.itemCost">
            <div class="col-12">
            <h4>Total Amount: <span class="font-weight-bold">${{totalPrice.toFixed(2)}}</span></h4>
            </div>
        </div>
        <div class="row" *ngIf="appliedCoupon && appliedCoupon.discountType !== 'month'">
            <div class="col-12">
            <h5>Discount:
                <span *ngIf="appliedCoupon && appliedCoupon.discountType === 'fixed'">${{appliedCoupon?.discountAmount}}</span>
                <span *ngIf="appliedCoupon && appliedCoupon.discountType === 'percentage'">{{appliedCoupon?.discountAmount}}%</span>
            </h5>
            </div>
        </div>
        <div class="row" *ngIf="appliedCoupon && appliedCoupon.discountType === 'month'">
            <div class="col-12">
            <h5>{{appliedCoupon?.description}}</h5>
            </div>
        </div>
        <div class="row" *ngIf="data && data?.itemCost">
            <div class="col-12">
            <h4>Payable Amount: <span class="font-weight-bold">${{payableAmount.toFixed(2)}}</span></h4>
            </div>
        </div>
        <div class="col-12">
        <div class="profile-field pl-0 mt-2">
            <div class="row">
                <div class="col-md-6 px-0">
                    <input placeholder="Enter Coupon Code" [(ngModel)]="couponCode" autocomplete="off">
                </div>
                <div class="col-md-2">
                    <button class="greenbtn" (click)="applyCoupon()">Apply</button>
                </div>
                <div class="col-md-4" *ngIf="appliedCoupon">
                    <button class="greenbtn">Coupon Applied</button>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
        <div class="row">
            <div class="col-md-6 px-0">
                <h4 class="d-flex justify-content-between">Enter your card details</h4>
            </div>
            <div class="col-md-6">
                <img src="assets/images/card.png" alt="" width="150" class="ml-auto d-block">
            </div>
        </div>
        </div>
        <div class="col-12">
        <div class="profile-field px-0">

            <div class="row" [formGroup]="paymentForm">
                <div class="col-md-12 px-0">
                    <input placeholder="Full Name" formControlName="full_name" autocomplete="off">
                    <mat-error *ngIf="paymentForm.controls.full_name.touched && paymentForm.controls.full_name.hasError('required')">
                        Card holder Name is required.
                    </mat-error>

                    <mat-error *ngIf="paymentForm.controls.full_name.touched && paymentForm.controls.full_name.hasError('pattern')">
                        Card holder Name consists of white spaces.
                    </mat-error>
                </div>

                <div class="col-md-12 position-relative px-0">
                    <input placeholder="Address" formControlName="address" (input)="getPlace('auto')" id="auto" autocomplete="off">
                    <mat-error *ngIf="paymentForm.controls.address.touched && paymentForm.controls.address.hasError('required')">
                        Card holder Address is required.
                    </mat-error>

                    <mat-error *ngIf="paymentForm.controls.address.touched && paymentForm.controls.address.hasError('pattern')">
                        Card holder Address consists of white spaces.
                    </mat-error>
                </div>

                <div class="col-md-12 px-0">
                    <input type="text" mask="0*" placeholder="Card Number" formControlName="card_number" autocomplete="off">
                    <mat-error *ngIf="paymentForm.controls.card_number.touched && paymentForm.controls.card_number.hasError('required')">
                        Card number is required.
                    </mat-error>

                    <mat-error *ngIf="paymentForm.controls.card_number.touched && paymentForm.controls.card_number.hasError('minlength')">
                        Card number should be of minimum 12 digits
                    </mat-error>

                    <mat-error *ngIf="paymentForm.controls.card_number.touched && paymentForm.controls.card_number.hasError('maxlength')">
                        Card number should be of maximum 16 digits
                    </mat-error>
                </div>

                <div class="col-md-4 pl-0">
                    <select placeholder="Expiry Year" class="ng-untouched ng-pristine ng-valid" formControlName="exp_year"
                        (change)="checkValidity('year')">
                        <option value=''>select year</option>
                        <option [value]="year" *ngFor="let year of yearArray" [disabled]="parseInt(paymentForm.controls.exp_month.value) < currentMonth && year === currentYear">{{year}}</option>
                    </select>
                    <mat-error *ngIf="paymentForm.controls.exp_year.touched && paymentForm.controls.exp_year.hasError('required')">
                        Year is required.
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls.exp_year.touched && paymentForm.controls.exp_year.hasError('minlength')">
                        Year must be YYYY format.
                    </mat-error>
                </div>

                <div class="col-md-4">
                    <select placeholder="Expiry month (MM)" class="ng-untouched ng-pristine ng-valid" formControlName="exp_month"
                        (change)="checkValidity('month')">
                        <option value=''>select month</option>
                        <option [value]="month" *ngFor="let month of monthArray"  [disabled]="parseInt(paymentForm.controls.exp_year.value) === currentYear && parseInt(month) < currentMonth">{{month}}</option>
                    </select>
                    <mat-error *ngIf="paymentForm.controls.exp_month.touched && paymentForm.controls.exp_month.hasError('required')">
                        Month is required.
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls.exp_month.touched && paymentForm.controls.exp_month.hasError('minlength')">
                        Month must be MM format.
                    </mat-error>
                </div>

                <div class="col-md-4 pr-0">
                    <input type="password" formControlName="cvv" mask="000" placeholder="CVV" autocomplete="off">
                    <mat-error *ngIf="paymentForm.controls.cvv.touched && paymentForm.controls.cvv.hasError('required')">
                        CVV is required.
                    </mat-error>

                    <mat-error *ngIf="paymentForm.controls.cvv.touched && paymentForm.controls.cvv.hasError('minlength')">
                        CVV must be of 3 digits.
                    </mat-error>
                </div>
            </div>

            <div class="row">
                <div class="d-flex align-items-center">
                    <button class="cancelbtn" (click)="closeDialog()" [disabled]="disableButton">Cancel</button>
                </div>
                <div class="col-md-2" *ngIf="data?.backBtn">
                    <button class="cancelbtn" (click)="goBack()" [disabled]="disableButton">Back</button>
                </div>
                <div class="d-flex align-items-center">
                    <button class="greenbtn mr-2 px-4" (click)="submitPaymentDetails()" [disabled]="disableButton">Pay</button>
                </div>
            </div>
        </div>
        </div> -->
</div>