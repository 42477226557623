import { Component, OnInit } from '@angular/core';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-dynamic-banner',
  templateUrl: './dynamic-banner.component.html',
  styleUrls: ['./dynamic-banner.component.scss']
})
export class DynamicBannerComponent implements OnInit {
  homePageStatic: any;
  BASEURL = environment.IMAGE_BASE_URL;
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getBannerData();
  }
  getBannerData() {
    this.api.get('masterdata/static-banner/static-banner').subscribe((res: any) => {
      if (res.status === 200) {
        this.homePageStatic = res.data;
      }
    })
  }
}
