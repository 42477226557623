import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';
import { environment } from '@envconfig';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() footerData: any;
  currentYear = new Date().getFullYear();
  @Input() authenticated: any =false;
  homePageStatic: any;
  settingsData: any = [];
  BASEURL = environment.IMAGE_BASE_URL;
  constructor(private api: ApiService, private storage: StorageService) { }

  ngOnInit(): void {
    this.getBannerData();
    this.getSettingsData('playstore');
    this.getSettingsData('apple_store');
    this.getSettingsData('twitter');
    this.getSettingsData('facebook');
    this.getSettingsData('instragram');
    this.getSettingsData('youtube');
  }

  getBannerData(){
    // masterdata/dynamic-content/home-page
    this.api.get('masterdata/static-banner/footer-banner').subscribe((res:any) => {
      if(res.status === 200){
        this.homePageStatic = res.data;
      }
    })
  }

  isMerchantLoggedIn(){
    if(this.storage.getDataField('role') && this.storage.getDataField('role') === 'merchant'){
      return true;
    }else{
      return false;
    }
  }

  getSettingsData(slug: string){
    this.api.get(`masterdata/settings/${slug}`).subscribe((res:any) => {
      if(res.status === 200){
        this.settingsData[slug] = res.data.settingValue;
      }
    });
  }

}
