<div class="adminbanner mb-2" *ngIf="homePageStatic">
  <div class="container">
    <div class="moreadminBanner">
      <div class="dynamicbannerOuter">
        <div class="footerTop">
          <img [src]="BASEURL + 'homepage_banner/' + homePageStatic?.banner" alt="" class="img-fluid" onerror="this.src = './assets/images/no-image.png'">
          <div class="footerTopOverlap">
            <div class="" *ngIf="homePageStatic">
              <div class="">
                <div class="">
                  <!-- <h2 class="text-white">{{homePageStatic?.title}}</h2> -->
                  <span class="zpy-3 pt-1 small-one"
                    [ngClass]="{'text-center' : (homePageStatic?.small_text?.text_align==='center' || homePageStatic?.small_text?.text_align===''), 'text-left' : homePageStatic?.small_text?.text_align==='left', 'text-right' : homePageStatic?.small_text?.text_align==='right', 'text-justify' : homePageStatic?.small_text?.text_align==='justify'}"
                    [ngStyle]="{'font-weight':homePageStatic?.small_text?.font_size,'color':homePageStatic?.small_text?.color_code}">{{homePageStatic?.small_text?.text}}

                  </span>
                  <span class="d-block me-one"
                    [ngClass]="{'text-center' : (homePageStatic?.medium_text?.text_align==='center' || homePageStatic?.medium_text?.text_align===''), 'text-left' : homePageStatic?.medium_text?.text_align==='left', 'text-right' : homePageStatic?.medium_text?.text_align==='right', 'text-justify' : homePageStatic?.medium_text?.text_align==='justify'}"
                    [ngStyle]="{'font-weight':homePageStatic?.medium_text?.font_size,'color':homePageStatic?.medium_text?.color_code}">{{homePageStatic?.medium_text?.text}}</span>
                  <span class="large-one"
                    [ngClass]="{'text-center' : (homePageStatic?.large_text?.text_align==='center' || homePageStatic?.large_text?.text_align===''), 'text-left' : homePageStatic?.large_text?.text_align==='left', 'text-right' : homePageStatic?.large_text?.text_align==='right', 'text-justify' : homePageStatic?.large_text?.text_align==='justify'}"
                    [ngStyle]="{'font-weight':homePageStatic?.large_text?.font_size,'color':homePageStatic?.large_text?.color_code}">{{homePageStatic?.large_text?.text}}</span>
                  <!-- -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
