<div class="merchent_wrap" *ngIf="bussinessType && bussinessType.merchantAccountStatus == 'Paid'">
    <div class="merchent_inner">
        <div class="days_remaining">
            <h3>Expires <br> Days Remaining</h3>
            <!-- <p>{{bussinessType?.account_info.remaining_days}}</p> -->
            <p>{{dayCount}}</p>
        </div>
        <div class="calender_sec">
            <ul>
                <li class="day">
                    Today
                </li>
                <li class="month">
                    <p>{{currentDate | date:'EEEE'}}</p>
                    <span>March</span>
                </li>
                <li class="date">
                    <h4>{{currentDate | date:'dd'}}</h4>
                </li>
                <li class="year">
                    <span>{{currentDate | date:'yyyy'}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>