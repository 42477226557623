<div class="cart_modal">
   <div class="">
    <h4>{{modalData?.product?.title}} </h4>
   </div> 
   <div class="space11">
    <div class="cart_modal_wrapper">
        <!-- <h3>${{modalData?.product?.price}} <span>for {{modalData?.product?.weight?.title}}</span></h3> -->

        <h3>
               <span class="w-one1">${{choosePrice?.price}}</span>  <span class="p-text1"> per {{choosePrice?.weightDetails?.title}}</span>
              </h3>
            <p> Available weights</p> 

        <div class="cart-modal-select">
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button *ngFor="let item of modalData?.product?.weightPrice"
                    (change)="choosePrice = item" [value]="item?.weight" [checked]="choosePrice?.weight == item?.weight">
                    {{item?.weightDetails?.title}}</mat-radio-button>
            </mat-radio-group>
            <div class="mt-3" *ngIf="onSubmit && choosePrice == ''" style="color: brown;font-size: 14px;">
                Please choose weight of the item.
            </div>
        </div>


        <p class="mt-21">Quantity</p>
        <div class="btn_group btn-h">
            <button class="m-button" (click)="updateProductQuantity('remove')"><img src="../../../assets/images/mi.svg"></button>
            <button class="q-button1">{{productQuantity}}</button>
            <button class="p-button" (click)="updateProductQuantity('add')"><img src="../../../assets/images/plus-one.svg"></button>
        </div>

    </div>

    <div class="mt-4">
        <button (click)="addToCart()">Add to Cart</button>
    </div>

    <div class="close_btn">
        <button (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
</div>