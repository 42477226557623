<div class="modalwrap">
    <div class="container" *ngIf="data">
        <div class="row">
            <h4 class="d-flex justify-content-between">{{data?.title}}<button class="close btn" (click)="closeDialog()">x</button></h4>
        </div>
        <div class="profile-field">

            <ng-container *ngIf="data?.type !== 'password' && data?.type !== 'phone'">
                <div class="row" *ngIf="data?.type === 'email'">
                    <input placeholder="someone@example.com" [(ngModel)]="textInput" (input)="errorMsg = ''">
                </div>

                <div class="row" *ngIf="data?.type === 'username'">
                    <input placeholder="username" [(ngModel)]="textInput" (input)="errorMsg = ''">
                </div>

                <div class="row" *ngIf="data?.type === 'fullName'">
                    <input placeholder="Full name" [(ngModel)]="textInput" (input)="errorMsg = ''">
                </div>

                <div class="row" *ngIf="data?.type === 'address'">
                    <div class="addss">
                        <input placeholder="Delivery Address" [(ngModel)]="textInput" (input)="errorMsg = ''">
                    </div>
                </div>

                <div class="row" *ngIf="data?.type === 'bio'">
                    <textarea [(ngModel)]="textInput" (input)="errorMsg = ''"></textarea>
                    <p>255 characters max</p>
                    <p>{{textInput?.length}}/255</p>
                </div>

                <div class="row" *ngIf="data?.type === 'dob'">
                    <mat-calendar [maxDate]="maxDt" [selected]="selectedDate" (selectedChange)="onSelect($event)"></mat-calendar>
                </div>

                <div class="row" *ngIf="errorMsg">
                    <p>{{errorMsg}}</p>
                </div>

                <div class="row">
                    <button class="cancelbtn btn-danger mr-2" (click)="closeDialog()">Cancel</button>
                    <button (click)="toSave()" [disabled]="!enableButton()" class="greenbtn">Save</button>
                </div>
            </ng-container>

            <ng-container *ngIf="data?.type === 'password'">
                <div class="row" [formGroup]="pwdForm">
                    <div class="col-md-12" [style.padding-bottom.px]="10">
                        <label>Current Password</label>
                        <div class="input-eye-wrap">
                            <input [type]="showPwd ? 'text' : 'password'" formControlName="currPwd" [style.margin-bottom.px]="8">
                            <button class="eyebtn" (click)="showPwd = !showPwd" [ngClass]="showPwd ? 'text' : 'password'">
                            <img *ngIf="!showPwd" src="assets/images/eye.svg" width="15" class="eyeclose"/>
                            <img *ngIf="showPwd" src="assets/images/view.svg" width="15" class="eyeopen"/>
                        </button>
                        </div>
                        <mat-error *ngIf="pwdForm.controls.currPwd.touched && pwdForm.controls.currPwd.hasError('required')">
                            This is mandatory
                        </mat-error>
                        <mat-error *ngIf="pwdForm.controls.currPwd.touched && pwdForm.controls.currPwd.hasError('minlength')">
                            Minimum 8 characters required
                        </mat-error>
                    </div>
                    <div class="col-md-12">
                        <label>New Password</label>
                        <div class="input-eye-wrap">
                            <input [type]="showNewPwd ? 'text' : 'password'" formControlName="newPwd" [style.margin-bottom.px]="8">
                            <button class="eyebtn" (click)="showNewPwd = !showNewPwd" [ngClass]="showNewPwd ? 'text' : 'password'">
                            <img *ngIf="!showNewPwd" src="assets/images/eye.svg" width="15" class="eyeclose"/>
                            <img *ngIf="showNewPwd" src="assets/images/view.svg" width="15" class="eyeopen"/>
                        </button>
                        </div>
                        <p class="text-left" [style.margin-bottom.px]="0">Atleast 8 characters.</p>
                        <mat-error *ngIf="pwdForm.controls.newPwd.touched && pwdForm.controls.newPwd.hasError('required')">
                            This is mandatory
                        </mat-error>
                        <mat-error *ngIf="pwdForm.controls.newPwd.touched && pwdForm.controls.newPwd.hasError('minlength')">
                            Minimum 8 characters required
                        </mat-error>
                    </div>
                </div>
                <div class="row" [style.margin-top.px]="10">
                    <div class="col-sm-12">
                        <button class="cancelbtn d-inline-block btn-danger mr-2" (click)="closeDialog()">Cancel</button>
                        <button [disabled]="!pwdForm.valid" (click)="changePwd()" class="greenbtn d-inline-block">Save</button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="data?.type === 'phone'">
                <ng-container *ngIf=!otpStep>
                    <div class="row">
                        <input placeholder="" mask="(000) 000-0000" [(ngModel)]="textInput">
                        <p>We will send you a code to verify your number</p>
                    </div>
                    <div class="row">
                        <button class="cancelbtn btn-danger mr-2" (click)="closeDialog()">Cancel</button>
                        <button [disabled]="!enableButton()" (click)="sendOtp()" class="greenbtn">Continue</button>
                    </div>
                </ng-container>

                <ng-container *ngIf=otpStep>
                    <div class="row mb-3">
                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="configOTP" class="otp-inp"></ng-otp-input>
                    </div>
                    <div class="row">
                        <button class="cancelbtn btn-danger mr-2" (click)="closeDialog()">Cancel</button>
                        <button [disabled]="!otpCode" (click)="verifyAndSave()" class="greenbtn">Continue</button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>