import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { AutocompleteComponent } from 'src/app/address/google-places/google-places.component';
import { environment } from '@envconfig';
import { EventService } from '@project/services/event.service';


@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {
  // @ViewChild("auto") placesRef : GooglePlaceDirective;
  paymentForm: FormGroup;
  disableButton = false;

  itemPrice: any;
  couponCode:any
  appliedCoupon: any;
  preFilledData: any;
  tax = 0;
  sc = 0;
  totalPrice = 0;
  payableAmount = 0;
  finalPaymentData:any
  
  monthArray = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  yearArray: any = [];
  cardMask = `0000 0000 0000||0000 0000 0000 0||0000 0000 0000 00||0000 0000 0000 000||0000 0000 0000 0000||0000 0000 0000 0000 0||0000 0000 0000 0000 00||0000 0000 0000 0000 000`;

  universalPromotions: any = [];
  locationPromotions: any = [];
  slideConfig2 = {
    slidesToShow: 1, slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  BASEURL = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
  public dialogRef: MatDialogRef<PaymentDialogComponent>, private fb: FormBuilder, private api: ApiService,private event:EventService) {
    dialogRef.disableClose = true;
    this.router.events.subscribe(() => {
      dialogRef.close('route-change');
    });
   }

  ngOnInit(): void {
    let paymentDataObject:any = sessionStorage.getItem('paymentObject')
    this.finalPaymentData = JSON.parse(paymentDataObject)
    console.log("paymentDataObject",this.finalPaymentData);
    
    this.BASEURL = environment.IMAGE_BASE_URL;
    this.formInit();
    this.getPromotions('universal');
    this.getPromotions('location');
    console.log('payment data',this.data);
    
    if(this.data && this.data.itemCost){  this.itemPrice = parseFloat(this.data.itemCost);  }
    if(this.data && this.data.tax){  this.tax = parseFloat(this.data.tax);  }
    if(this.data && this.data.sc){  this.sc = parseFloat(this.data.sc);  }
    this.totalPrice = this.itemPrice + this.tax + this.sc;
    // this.totalPrice = this.itemPrice + this.tax + this.sc;
    this.payableAmount = this.totalPrice;

    if (this.data?.preFillerData) {
      this.preFilledData = this.data?.preFillerData;
      this.couponCode = this.preFilledData?.code;
      if (this.couponCode) {  this.applyCoupon();  }
    }
    for (let i = this.currentYear; i < (this.currentYear + 10); i++) {  this.yearArray.push(i);  }
  }

  formInit(){
    this.paymentForm = this.fb.group({
      full_name: [this.finalPaymentData?.name ? this.finalPaymentData.name : '', [Validators.required, Validators.pattern(/^[^\s-]/)]],
      card_number: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(16)]],
      exp_month: ['', [Validators.required, Validators.minLength(2)]],
      exp_year: ['', [Validators.required, Validators.minLength(4)]],
      cvv: ['', [Validators.required, Validators.minLength(3)]],
      address: [this.finalPaymentData?.paymentaddress?.addressLine ? this.finalPaymentData.paymentaddress.addressLine : '', [Validators.required, Validators.pattern(/^[^\s-]/)]],
      // ...............................................................
      City: [this.finalPaymentData?.paymentaddress?.city ? this.finalPaymentData.paymentaddress.city : '', [Validators.required, Validators.pattern(/^[^\s-]/)]],
      State: [this.finalPaymentData?.paymentaddress?.state ?  this.finalPaymentData?.paymentaddress?.state : '', [Validators.required, Validators.pattern(/^[^\s-]/)]],
      Zip:[this.finalPaymentData?.paymentaddress?.zipcode ? this.finalPaymentData?.paymentaddress?.zipcode : '', [Validators.required, Validators.pattern(/^[^\s-]/)]],
      // ...............................................................

    })
    if (this.preFilledData) {
      this.paymentForm.patchValue({
        full_name: this.preFilledData?.full_name,
        card_number: this.preFilledData?.card_number,
        exp_month: this.preFilledData?.exp_month,
        exp_year: this.preFilledData?.exp_year,
        cvv: this.preFilledData?.cvv,
        address: this.preFilledData?.address,
        // ...............................................................
      City: this.preFilledData?.city,
      State: this.preFilledData?.state,
      Zip:this.preFilledData?.zip
      // ...............................................................

      });
    }
  }

  getPromotions(type: string = '') {
      this.api.post('promocode/list', { code_based_on: type }).subscribe((res: any) => {
        if (res.status === 200) {
          if (type === 'universal') {
            this.universalPromotions = res.data;
          } else {
            this.locationPromotions = res.data;
          }
        } else {
          if (type === 'universal') {
            this.universalPromotions = [];
          } else {
            this.locationPromotions = [];
          }
        }
      }, err => {
        if (type === 'universal') {
          this.universalPromotions = 'error';
        } else {
          this.locationPromotions = 'error';
        }
        this.api.alert(err.message, 'error');
      });
  }

  applyCoupon(){
    this.appliedCoupon = undefined;  
    if(this.couponCode && this.couponCode.length){
      const obj = {
        code: this.couponCode,
        city: this.data?.city,
        state: this.data?.state,
        country: this.data?.country,
        type: this.data?.productType
      }
      this.api.post('promocode/check', obj).subscribe((res:any) => {
        this.itemPrice = this.data.itemCost
        if(res.status === 200){
          this.appliedCoupon = res.data;
          (document.getElementById('couponInput') as HTMLInputElement).setAttribute("readonly","true")
          if(res.data.discountType === 'fixed'){
            // this.itemPrice = parseFloat(this.itemPrice) - parseFloat(res.data.discountAmount);
            this.payableAmount = this.totalPrice - parseFloat(res.data.discountAmount);
          }else if(res.data.discountType === 'percentage'){
            let fixedOff = 1 - (parseFloat(res.data.discountAmount)/100);
            this.payableAmount = parseFloat((this.totalPrice*fixedOff).toFixed(2));
          }
          this.api.alert(res.message, 'success')
        }else{
          this.itemPrice = this.data.itemCost
          this.api.alert(res.message, 'warning')
        }
      }, err => {
        this.itemPrice = this.data.itemCost
        this.api.alert('Some error occurred', 'error');
      })
    }
  }

  getTotalAmt(){
    let s = 0;
    s += parseFloat(this.itemPrice);
    if(this.data.sc){  s += parseFloat(this.data.sc);  }
    if(this.data.tax){  s+= parseFloat(this.data.tax);  }
    return s
  }

  submitPaymentDetails(){
    // * 		city: Ajax
    // * 		state: Ontario
    // * 		country: CA
    // * 		postal_code: L1S 4S4
    // * 		spot: Spot 1
    // * 		category: dispensary
    // * 		date: 2023-12-01
    // * 		full_name: Souvik
    // * 		card_number: 4242424242424242
    // * 		expiry: 28/02
    // * 		cvv: 123
    // * 		type: spot
    // * 		lat: 43.8394527
    // * 		long: -79.0205361
    // * 		address: 75 Centennial Rd, Ajax, ON L1S 4S4, Canada
    // * 		start_date: 2023-12-01
    // * 		end_date: 2023-12-31
    // * 		month: 1
    // * 		unit_no: 22
    // * 		phone_no: 2223232323
    
    if(this.paymentForm.valid){
      const currYr = new Date().getFullYear();
      const currMn = new Date().getMonth() + 1;
      let validExp = false;
      if(+this.paymentForm.value.exp_year > currYr){
        let diff = +this.paymentForm.value.exp_year - currYr;
        if(diff <= 10){
          if(+this.paymentForm.value.exp_month > 0 && +this.paymentForm.value.exp_month <= 12){
            validExp = true
          }
        }
      }else if(+this.paymentForm.value.exp_year === currYr){
        if(+this.paymentForm.value.exp_month > 0 && +this.paymentForm.value.exp_month >= currMn && +this.paymentForm.value.exp_month <= 12){
          validExp = true
        }
      }else{  validExp = false;  }

      if(validExp){  
        if(this.data && this.data.type === 'direct-pay'){
          console.log("check----->>>payment-----direct");     
          let dataset = this.paymentForm.value;   
          dataset.expiry = dataset.exp_year.slice(-2)+'/'+ dataset.exp_month
          delete dataset.exp_year;
          delete dataset.exp_month;
          if(this.appliedCoupon && this.appliedCoupon.code){
            dataset = {...dataset, code: this.appliedCoupon.code}
          }
          this.dialogRef.close(dataset);
        } else{
          console.log("check----->>>payment");      
          this.disableButton = true;
          const obj = new FormData();
          this.paymentForm.value.expiry = this.paymentForm.value.exp_year.slice(-2)+'/'+ this.paymentForm.value.exp_month
          delete this.paymentForm.value.exp_year;
          delete this.paymentForm.value.exp_month;
          for(const key in this.paymentForm.value){  obj.set(key, this.paymentForm.value[key]);  }
          console.log("obj",obj);
          
          this.api.postMultiData('payment/save', obj).subscribe((res:any) => {
            if(res.status === 200){
              this.event.changeStatus.next(true)
              this.api.alert('Payment done successfully', 'success');
              this.dialogRef.close(true);
            }else if(res.status === 406){
              // this.disableButton = false;
              this.api.alert(res.message, 'warning');
              this.dialogRef.close('Not Acceptable')
            }else{
              this.disableButton = false;
              this.api.alert(res.message, 'warning');
            }
          }, err => {
            if(err.status === 406){
              // this.disableButton = false;
              this.api.alert(err.message, 'warning');
              this.dialogRef.close('Not Acceptable')
            }else{
              this.disableButton = false;
              this.api.alert(err.message, 'error');
            }
          });
        }
      }else{
        this.api.alert('Invalid expiry', 'warning');
      }
    }else{
      this.paymentForm.markAllAsTouched();
    }
  }

  closeDialog(){  
    console.log("s");
    
    this.dialogRef.close();  
  }

  getPlace(inputId: string): void {
    console.log("hi",inputId)
    const input = document.getElementById(inputId) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: ['US','CA'] }, // to restrict the search in a country
      // types: ['street_address']  // 'establishment' / 'address' / 'geocode' / 'street_address'
    };
    const place = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(place, 'place_changed', () => {
      let selectedPlace: any;
      selectedPlace = place.getPlace();
      let str = '';

      let city = '';
      let state = '';
      let zip = '';


      if(selectedPlace){
        str = selectedPlace.formatted_address;
        // this.paymentForm.patchValue({  address: str  });

        selectedPlace.address_components.forEach((ele:any) => {
          if(ele.types[0]=== 'locality'){
            city = ele.long_name
            console.log("city....",city);
            
          }
          if(ele.types[0] === 'administrative_area_level_1'){
            state = ele.long_name
            console.log("state...",state);
            
          }
          if (ele.types[0] === 'postal_code') {
            zip = ele.long_name;
            console.log("zip",zip);
          }
        });

        this.paymentForm.patchValue({
          address: str,
          City: city,
          State: state,
          Zip: zip,
          // lat: selectedPlace.geometry.location.lat(),
          // long: selectedPlace.geometry.location.lng()
        })


      }
    });
  }
  
  goBack(){  this.dialogRef.close('back');   }

  checkValidity(type: string): any {
    if (parseInt(this.paymentForm.controls.exp_year.value) === this.currentYear && parseInt(this.paymentForm.controls.exp_month.value) < this.currentMonth) {
      this.api.alert('Card validation over', 'warning');
      if (type === 'year') {
        this.paymentForm.patchValue({  exp_year : ''  });
      } else {
        this.paymentForm.patchValue({  exp_month : ''  });
      }
    }
  }

  parseInt(month: any): number {  return parseInt(month);  }

  getAddress(e: any): void {  }

  clearAddress(inputId: string): void {
    console.log(inputId);
    this.paymentForm.patchValue({  address: ''  });
    let input:any = document.getElementById(inputId) as HTMLInputElement;
    input.value = '';
  }


  // remove:boolean=false
  deleteCoupon(){
    (document.getElementById('couponInput') as HTMLInputElement).removeAttribute("readonly");
    (document.getElementById('couponInput') as HTMLInputElement).value = '';
    this.appliedCoupon=false
    this.payableAmount=this.totalPrice
  }

}
