import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-successfull-payment',
  templateUrl: './successfull-payment.component.html',
  styleUrls: ['./successfull-payment.component.scss']
})
export class SuccessfullPaymentComponent implements OnInit {
  totalPrice:number = 0;
  constructor(private router: Router,@Inject(MAT_DIALOG_DATA) public modalData: any,
  public dialogRef: MatDialogRef<SuccessfullPaymentComponent>,) { }

  ngOnInit(): void {
    console.log('SuccessfullPaymentComponent  ==> ',this.modalData);
    this.modalData?.checkoutAddData.forEach((element:any) => {
      if(element){
        this.totalPrice += element?.weightPrice[0]?.subTotal;
      }
    });
  }

  closeDialog(){
    this.dialogRef.close();  
    this.router.navigate(['/my-purchase']);
  }

}
