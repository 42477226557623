
<div class="modalwrap">
    <div class="modalClose" (click)="cancel()">
        <span class="material-icons">
            close
        </span>                
    </div>

    <mat-dialog-content>
        <img src="./assets/images/logo.svg" alt="" class="d-block mx-auto" id="logo">

        <div id="step1" class="d-block">
            <!-- <h1>Are You 19 Or Older?</h1> -->
            <h1>Are you 19 years old?</h1>

            <div class="d-flex justify-content-center">
                <button class="btn btn-primary rounded-pill px-md-5 px-auto py-2 mr-3 mb-3" (click)="submit()">Yes</button>
                <button class="btn btn-danger rounded-pill px-md-5 px-auto py-2 mb-3" (click)="cancel()">No</button>
            </div>

            <p>You must verify that you are 19 years of age or older to enter the site.</p>
        </div>
    </mat-dialog-content>
</div>
