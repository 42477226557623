
<div class="modalwrap registrationmodal">
    <div class="modalClose" (click)="onNoClick()" *ngIf="!isConfirm">
        <span class="material-icons">
            close
        </span>                
    </div>

    <mat-dialog-content>
        <ng-container *ngIf="data === 'step1'">
            <img src="./assets/images/logo.svg" alt="" class="d-block mx-auto" id="logo">
            <div id="step1" class="d-block">
                <h1>Confirm Registration ?</h1>
    
                <div class="d-flex justify-content-center ">
                    <button class="btn btn-danger rounded-pill px-md-5 px-auto py-2 mr-3 mb-3" (click)="onNoClick()">EDIT</button>
                    <button class="btn btn-primary rounded-pill px-md-5 py-2 px-auto mb-3" (click)="openNextStep()">CONFIRM!</button>
                </div>
    
            </div>
        </ng-container>
        

        <div id="step3" *ngIf="data === 'step3'" class="d-block">
            <h2 class="thankyouheading">Thank you for registering!</h2>
            <span class="material-icons text-primary">
                check_circle
            </span>
            <p classs="regMessage">Your account has been created and verification email has been sent to your register email address. Please click on the verification link included in the email to active your account.</p>
        </div>
    </mat-dialog-content>
</div>
