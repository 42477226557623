<div class="authHeaderWrap">
    <div class="container-fluid">
        <div class="row align-items-start flex-nowrap">
            <div class="powerby">
                <p>Powered By</p>
                <div class="logo">
                    <img src="./assets/images/logo.svg" alt="" routerLink="/home">
                </div>
            </div>
            <p class="date_joined">Date Joined: {{merchantUser?.merchant?.createdAt | date:'MMM d, y'}}</p>
            <ul class="header_listing_sec ">
                <!-- <li>Status : {{merchantUser?.merchant?.status}}</li>
                <li>Joining Date : {{merchantUser?.merchant?.createdAt | date:'MMM d, y'}}</li>
                <li>
                    <div class="loggin">
                        <p *ngIf="merchantUser">Welcome <span>{{merchantUser?.user?.fullName}}</span></p>
                        <span *ngIf="data" class="material-icons cursor-pointer text-white" [matMenuTriggerFor]="menu">
                            expand_more
                        </span>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item routerLink="/merchant-dashboard">Dashboard</button>
                            <button mat-menu-item routerLink="/edit-profile">Edit Profile</button>
                            <button mat-menu-item routerLink="/change-password">Change Password</button>
                            <button mat-menu-item routerLink="/my-purchases">My Purchases</button>
                            <button mat-menu-item (click)="toLogout()">Logout</button>
                        </mat-menu>
                    </div>
                </li>
                <li>Date: {{todayDate | date:'MMM d, y'}}</li> -->

                <!-- <li>Renewal Date: July 22, 24</li>
                <li>Member Since: July 22, 23</li> -->
                <!-- <li>Bus. Account Type: Single</li> -->

                <!-- <li><span class="purpleText">{{merchantUser?.merchant?.business_name ? merchantUser?.merchant?.business_name : 'No Business Name'}}</span></li> -->
            
                <li>Status: {{merchantUser?.merchant?.status}}</li>
                <li *ngIf="bussinessType && bussinessType?.merchantAccountStatus == 'Paid'">Start date: {{bussinessType.account_info.start_date | date:'MMM d, y'}}</li>
                <li *ngIf="bussinessType && bussinessType?.merchantAccountStatus == 'Paid'">Expire date: {{bussinessType.account_info.expiry_date | date:'MMM d, y'}}</li>
                <li class="bussiness_type">Bussiness Type: <span> {{bussinessType && bussinessType?.merchantAccountStatus ?
                    bussinessType?.merchantAccountStatus : ''}}</span></li>

                    


            </ul>
            <div class="days_remaining" *ngIf="bussinessType && bussinessType?.merchantAccountStatus == 'Paid'">
                <h3>Expires <br> Days Remaining</h3>
    
                <p>{{dayCount}}</p>
                <!-- <p>{{bussinessType?.account_info.remaining_days}}</p> -->
            </div>

            <div class="headerRight">
                <div class="notification_show">
                    <button class="notification_btn">
                        <img src="../../../assets/images/bell.png" alt="">
                        <span class="noti_num">2</span>
                    </button>
                </div>
                <div class="userInfo">
                    <div class="userImg">
                        <!-- <img src="../../../assets/images/user.png" alt=""> -->
                        <img src="{{BASEURL}}merchant/{{merchantUser?.merchant?.logo}}"
                            onerror="this.src = './assets/images/no-image.png'" alt="">
                    </div>
                    <div class="userDetails">
                        <ul>
                            <li>Date: {{todayDate | date}}</li>
                            <!-- <li>Status: {{merchantUser?.merchant?.status}}</li> -->
                            <li>
                                <div class="loggin">
                                    <p *ngIf="merchantUser">Welcome</p>
                                        <div class="user_name_wrap">
                                            <p>{{merchantUser?.user?.fullName}}</p> 
                                                <span *ngIf="data" class="material-icons cursor-pointer text-white"
                                                [matMenuTriggerFor]="menu">
                                                expand_more
                                        </span>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item routerLink="/merchant-dashboard">Dashboard</button>
                                            <!-- <button mat-menu-item routerLink="/edit-profile">Edit Profile</button> -->
                                            <button mat-menu-item routerLink="/accountInfo">Account Info</button>
                                            <button mat-menu-item routerLink="/change-password">Change Password</button>
                                            <button mat-menu-item routerLink="/my-purchases">My Purchases</button>
                                            <button mat-menu-item (click)="toLogout()">Logout</button>
                                        </mat-menu>
                                        </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>