<!-- <header>
  <div class="container" style="max-width: 1200px;">
    <div class="row padding-control">
      <div class="col-lg-2 col-md-2 col-sm-12">
        <div class="d-flex justify-content-lg-start justify-content-center">
          <div class="smenuBackDrop" (click)="scloseMenu()"></div>
          <div class="smainMenuWrap">
            <div class="d-flex align-items-center border-bottom py-2 mb-3 bg-primary">
              <div class="col-auto">
                <div class="userLogo">
                  <ng-container *ngIf="isLogin">
                    <img draggable="false"
                      [src]="loggedInUser && loggedInUser?.profileImage ? BASEURL+'user/'+loggedInUser?.profileImage : 'assets/images/user1.svg'"
                      onerror="this.src = 'assets/images/user1.svg'" width="130" alt="">
                    <div>
                      <p>Hello</p>
                      <h5 *ngIf="loggedInUser && loggedInUser?.username">{{loggedInUser?.username}} </h5>
                      <h5 *ngIf="loggedInUser && !loggedInUser?.username">User</h5>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!isLogin">
                    <img draggable="false" src="assets/images/user1.svg" width="130" alt="">
                    <div>
                      <a routerLink="/login">
                        <h5>User Sign In</h5>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-auto ml-auto class__menu--p">
                <button class="btn shadow-none text-secondary p-0 sclose" (click)="scloseMenu()">
                  <i class="material-icons align-middle">close</i>
                </button>
              </div>
            </div>
            <perfect-scrollbar style="max-width: 600px; max-height: 500px;">
              <div class="px-3 smenuList">
                <h5 class="sideHead">Categories</h5>
                <mat-accordion *ngIf="categories && categories !== 'error' && categories?.length">
                  <mat-expansion-panel *ngFor="let item of categories">
                    <mat-expansion-panel-header>
                      <mat-panel-title *ngIf="item?.subCategories && item?.subCategories?.length">
                        {{item?.title}}
                      </mat-panel-title>
                      <mat-panel-title *ngIf="!item?.subCategories || item?.subCategories?.length === 0"
                        (click)="gotoProducts(item)">
                        {{item?.title}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="item?.subCategories && item?.subCategories?.length">
                      <p *ngFor="let ele of item?.subCategories" style="cursor: pointer;"
                        (click)="gotoProducts(item, ele)">{{ele?.title}}</p>
                    </ng-container>
                    <ng-container *ngIf="!item?.subCategories || item?.subCategories?.length === 0">
                      <p>No sub categories available</p>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
                <p *ngIf="categories && categories !== 'error' && categories?.length === 0">No categories found</p>
                <p *ngIf="categories && categories === 'error'">Some error occurred</p>
              </div>



              <div class="px-3 smenuList my-5" *ngIf="isLogin">
                <h5 class="sideHead">My Account</h5>

                <a style="cursor: pointer;" class="py-3 d-block" routerLink="/account-settings">My Profile</a>
                <a style="cursor: pointer;" class="py-3 d-block" routerLink="/wishlist">My Favorites</a>
                <a style="cursor: pointer;" class="py-3 d-block" (click)="toLogout()">Logout</a>

              </div>

            </perfect-scrollbar>

          </div>
          <img src="./assets/images/logo.svg" alt="" routerLink="/" class="logo">
        </div>
      </div>
      <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12 search__area srch">
        <div class="searchWrap headerSearch d-lg-block">
          <div class="row mx-0 w-100">
            <div class="col-md-6 col-sm-6 col-6 px-0">
              <div class="inputWrap searh">
                <div (click)="showOpts = !showOpts">
                  <img src="./assets/images/search.svg" alt="" class="prefixInput">
                  <input type="text" placeholder="Products, retailers and more" [(ngModel)]="searchText"
                    (input)="showOpts = true; searchText?.length > 2 ? doSearch() : ''" (keyup.enter)="search()">
                </div>


                <div *ngIf="showOpts" (click)="showOpts = !showOpts">
                  <ul class="search-auto" *ngIf="searchText?.length <= 2">
                    <li routerLink="/dispensaries"><a>Dispensaries</a></li>
                    <li routerLink="/deliveries"><a>Deliveries</a></li>
                    <li routerLink="/maps"><a>Maps</a></li>
                    <li routerLink="/find-products"><a>Products</a></li>
                    <li routerLink="/deals"><a>Deals</a></li>
                  </ul>
                  <ng-container *ngIf="searchText?.length > 2">
                    <p class="search-auto p-3">Search result for {{searchText}}</p>
                    <p class="search-auto p-3" *ngIf="seachResult === 'error'">Something went wrong....</p>
                    <p class="search-auto p-3" *ngIf="seachResult !== 'error' && seachResult?.length === 0">
                      {{ searching ? 'Searching..' : 'No record found' }}</p>
                    <ng-container *ngIf="seachResult !== 'error' && seachResult?.length > 0">
                      <ul class="search-auto">
                        <li *ngFor="let result of seachResult" (click)="goToResult(result)">
                          <div class="search_Pro_txt d-flex align-items-center">
                            <div class="search_ProImg mr-2">
                              <img [src]="result?.type === 'Merchant' ? BASEURL + 'merchant/' + result?.logo : BASEURL + 'product/' + result?.image">
                            </div>
                            <div *ngIf="result?.type === 'Merchant'">
                              <a>{{result?.merchant_name}}</a>
                              <p class="mb-0">{{result?.address}}</p>
                              <p class="mb-0">{{result?.type}}</p>
                            </div>
                            <div *ngIf="result?.type !== 'Merchant'">
                              <a>{{result?.title}}</a>
                              <p class="mb-0">{{result?.type}}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>

                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-6 px-0">
              <div class="inputWrap loc-src">
                <a href="javascript:void(0)" (click)="redirectToCurrentLocation()">
                  <img src="./assets/images/map.svg" alt="" class="prefixInput">
                </a>
                <input type="text" class="location_input" [(ngModel)]="locationInp" name="palce-inp"
                  (input)="getPlace('auto')" id="auto" (focus)="locationSearchResult = true;focusFunction()"
                  (focusout)="locationSearchResult = false;focusOutFunction()" autocomplete="off">
                <div *ngIf="locationSearchResult" id="search_result">
                  <ul id="result" class="search-auto">
                    <li (mousedown)="searchByCurrentLocation()">
                      <figure>
                        <img [src]="current_location" alt="">
                      </figure>
                      <span>Currernt Location</span>
                    </li>
                    <li *ngFor="let location of locationSearchHistory"
                      (mousedown)="onClickLocation(location?.place_id,location?.location_name)">
                      <figure>
                        <img [src]="location_history" alt="">
                      </figure>
                      <span>{{location?.location_name}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12">
            <div class="menuBackDrop d-lg-none" (click)="closeMenu()"></div>
            <div class="mainMenuWrap" (click)="closeMenu()">
              <div class="d-none align-items-center border-bottom py-2 mb-3 ">
                <div class="col-auto">
                  <a routerLink='/' class="weblogo">
                    <img draggable="false" src="assets/images/logo.svg" width="130" alt="">
                  </a>
                </div>
                <div class="col-auto ml-auto class__menu--p">
                  <button class="btn shadow-none text-secondary p-0"><i
                      class="material-icons align-middle">close</i></button>
                </div>
              </div>

              <ul class="menuList list-unstyled m-0 d-lg-flex">
                <li routerLinkActive="active" routerLink="/dispensaries">Dispensaries</li>
                <li routerLinkActive="active" routerLink="/deliveries">Deliveries </li>
                <li routerLinkActive="active" routerLink="/maps">Maps </li>
                <li routerLinkActive="active" routerLink="/find-products">Products </li>
                <li routerLinkActive="active" routerLink="/deals">Deals </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-lg-end justify-content-center">
        <div class="d-inline-flex justify-content-center">
          <div class="d-flex justify-content-lg-end justify-content-center" *ngIf="!isLogin">
            <ng-container *ngIf="!isMerchant()">
              <button class="btn btn-white rounded-pill text-color1" routerLink="/login" style="font-size: 12px;">User
                Sign In</button>
              <button class="btn btn-primary rounded-pill" routerLink="/register" style="font-size: 12px;">New User Sign
                Up</button>
            </ng-container>

            <ng-container *ngIf="isMerchant()">
              <button class="btn btn-primary rounded-pill" routerLink="/merchant-dashboard">Merchant Dashboard</button>
            </ng-container>
          </div>
        </div>
        <div class="d-inline-flex justify-content-lg-end justify-content-center">
          <div class="head-icons ml-2 d-none">
            <img src="./assets/images/search.svg" alt="" class="d-lg-none d-inline-block cursor-pointer"
              (click)="searchWrapOpen()">
          </div>
          <div class="head-icons d-none">
            <button (click)="openMenu()" class="btn shadow-none text-secondary px-0"><i
                class="material-icons align-middle">menu_open</i></button>
          </div>
          <div class="d-flex justify-content-lg-end justify-content-center position-relative" *ngIf="isLogin">
            <div class="head-icons" routerLink="/notification">
              <span class="material-icons">
                notifications
              </span>
            </div>
            <div class="head-icons" routerLink="/wishlist">
              <span class="material-icons">
                favorite
              </span>
            </div>
            <div class="head-icons" routerLink="/my-cart">
              <span class="material-icons">
                shopping_cart
              </span>
            </div>
            <div class="head-icons" (click)="openPriceDrop()">
              <span class="material-icons">
                person
              </span>
            </div>

            <div class="profileDrop" (click)="openPriceDrop()">
              <div class="d-flex border-bottom pb-3">
                <figure>
                  <img
                    [src]="loggedInUser && loggedInUser?.profileImage ? BASEURL+'user/'+loggedInUser?.profileImage : 'assets/images/user1.svg'"
                    onerror="this.src = 'assets/images/user1.svg'" alt="">
                </figure>
                <div class="userName">
                  <p *ngIf="loggedInUser && loggedInUser?.username">{{loggedInUser?.username}}</p>
                  <p *ngIf="loggedInUser && !loggedInUser?.username">User</p>
                  <p class="cursor-pointer" routerLink="/account">View Account</p>
                </div>
              </div>
              <ul class="list-unstyled">
                <li routerLink="/account-settings"><span class="material-icons mr-2 mt-1">
                    settings
                  </span>Account Settings</li>
                  <li routerLink="/profile-settings"><span class="material-icons mr-2 mt-1">
                    person 
                  </span>My Profile</li>
                <li routerLink="/wishlist"><span class="material-icons mr-2 mt-1">
                    favorite_border
                  </span>Favorites</li>
                <li routerLink="/recently-viewed"><span class="material-icons mr-2 mt-1">
                    visibility
                  </span>Recently Viewed</li>
                <li routerLink="/my-purchase"><span class="material-icons mr-2 mt-1">
                    shopping_cart
                  </span>My Orders</li>
                <li (click)="toLogout()"><span class="material-icons mr-2 mt-1">
                    logout
                  </span>Logout</li>
                <hr>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

<header class="header-wrapper">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-2 col-md-3 logo">
        <!-- <p class="powered-by">Powered by</p> -->
        <div class="logo-holder">
          <div class="menuBackDrop d-lg-none" (click)="closeMenu()"></div>
          <div class="d-flex justify-content-lg-start justify-content-center">
            <div class="smenuBackDrop" (click)="scloseMenu()"></div>
            <div class="smainMenuWrap">
              <div class="d-flex align-items-center border-bottom py-2 mb-3 bg-primary">
                <div class="col-auto">
                  <div class="userLogo">
                    <ng-container *ngIf="isLogin">
                      <img draggable="false"
                        [src]="loggedInUser && loggedInUser?.profileImage ? BASEURL+'user/'+loggedInUser?.profileImage : 'assets/images/user1.svg'"
                        onerror="this.src = 'assets/images/user1.svg'" width="130" alt="">
                      <div>
                        <p>Hello</p>
                        <h5 *ngIf="loggedInUser && loggedInUser?.username">{{loggedInUser?.username}} </h5>
                        <h5 *ngIf="loggedInUser && !loggedInUser?.username">User</h5>
                      </div>
                    </ng-container>
  
                    <ng-container *ngIf="!isLogin">
                      <img draggable="false" src="assets/images/user1.svg" width="130" alt="">
                      <div>
                        <a routerLink="/login">
                          <h5>User Sign In</h5>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-auto ml-auto class__menu--p">
                  <button class="btn shadow-none text-secondary p-0 sclose" (click)="scloseMenu()">
                    <i class="material-icons align-middle">close</i>
                  </button>
                </div>
              </div>
              <perfect-scrollbar style="max-width: 600px; max-height: 500px;">
                <div class="px-3 smenuList">
                  <h5 class="sideHead">Categories</h5>
                  <mat-accordion *ngIf="categories && categories !== 'error' && categories?.length">
                    <mat-expansion-panel *ngFor="let item of categories">
                      <mat-expansion-panel-header>
                        <mat-panel-title *ngIf="item?.subCategories && item?.subCategories?.length">
                          {{item?.title}}
                        </mat-panel-title>
                        <mat-panel-title *ngIf="!item?.subCategories || item?.subCategories?.length === 0"
                          (click)="gotoProducts(item)">
                          {{item?.title}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-container *ngIf="item?.subCategories && item?.subCategories?.length">
                        <p *ngFor="let ele of item?.subCategories" style="cursor: pointer;"
                          (click)="gotoProducts(item, ele)">{{ele?.title}}</p>
                      </ng-container>
                      <ng-container *ngIf="!item?.subCategories || item?.subCategories?.length === 0">
                        <p>No sub categories available</p>
                      </ng-container>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <p *ngIf="categories && categories !== 'error' && categories?.length === 0">No categories found</p>
                  <p *ngIf="categories && categories === 'error'">Some error occurred</p>
                </div>
  
  
  
                <div class="px-3 smenuList my-5" *ngIf="isLogin">
                  <h5 class="sideHead">My Account</h5>
  
                  <a style="cursor: pointer;" class="py-3 d-block" routerLink="/account-settings">My Profile</a>
                  <a style="cursor: pointer;" class="py-3 d-block" routerLink="/wishlist">My Favorites</a>
                  <a style="cursor: pointer;" class="py-3 d-block" (click)="toLogout()">Logout</a>
  
                </div>
  
              </perfect-scrollbar>
  
            </div>
          </div>
          <a routerLink="/"><img src="./assets/images/logo.svg" alt=""></a>
        </div>
      </div>
      <div class="col-12 col-lg-7 col-md-6 search-region">
        <div class="search-region-holder">
          <div class="search-inputWrap">
            <div class="inputWrap search">
              <div (click)="showOpts = !showOpts">
                <img src="./assets/images/search-green.svg" alt="" class="prefixInput">
                <input type="text" placeholder="Products, retailers and more" [(ngModel)]="searchText"
                  (input)="showOpts = true; searchText?.length > 2 ? doSearch() : ''" (keyup.enter)="search()">
              </div>
  
  
              <div *ngIf="showOpts" (click)="showOpts = !showOpts">
                <ul class="search-auto" *ngIf="searchText?.length <= 2">
                  <li routerLink="/dispensaries"><a>Dispensaries</a></li>
                  <li routerLink="/deliveries"><a>Deliveries</a></li>
                  <li routerLink="/maps"><a>Maps</a></li>
                  <li routerLink="/find-products"><a>Products</a></li>
                  <li routerLink="/deals"><a>Deals</a></li>
                </ul>
                <ng-container *ngIf="searchText?.length > 2">
                  <p class="search-auto p-3">Search result for {{searchText}}</p>
                  <p class="search-auto p-3" *ngIf="seachResult === 'error'">Something went wrong....</p>
                  <p class="search-auto p-3" *ngIf="seachResult !== 'error' && seachResult?.length === 0">
                    {{ searching ? 'Searching..' : 'No record found' }}</p>
                  <ng-container *ngIf="seachResult !== 'error' && seachResult?.length > 0">
                    <ul class="search-auto">
                      <li *ngFor="let result of seachResult" (click)="goToResult(result)">
                        <div class="search_Pro_txt d-flex align-items-center">
                          <div class="search_ProImg mr-2">
                            <img [src]="result?.type === 'Merchant' ? result?.logo ? BASEURL + 'merchant/' + result?.logo : './assets/images/no-image.png': BASEURL + 'product/' + result?.image">
                          </div>
                          <div *ngIf="result?.type === 'Merchant'">
                            <a>{{result?.merchant_name}}</a>
                            <p class="mb-0">{{result?.address}}</p>
                            <p class="mb-0">{{result?.type}}</p>
                          </div>
                          <div *ngIf="result?.type !== 'Merchant'">
                            <a>{{result?.title}}</a>
                            <p class="mb-0">{{result?.type}}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
  
              </div>
            </div>
            <div class="inputWrap loc-src">
              <a href="javascript:void(0)" class="prefixInput" (click)="redirectToCurrentLocation()">
                <img src="./assets/images/map-green.svg" alt="">
              </a>
              <input type="text" class="location_input" [(ngModel)]="locationInp" name="palce-inp" 
                (input)="getPlace('auto')" id="auto" (focus)="locationSearchResult = true;focusFunction()"
                (focusout)="locationSearchResult = false;focusOutFunction()" autocomplete="off">
              <div *ngIf="locationSearchResult" id="search_result">
                <ul id="result" class="search-auto">
                  <li (mousedown)="searchByCurrentLocation()">
                    <figure>
                      <img [src]="current_location" alt="">
                    </figure>
                    <span>Currernt Location</span>
                  </li>
                  <li *ngFor="let location of locationSearchHistory"
                    (mousedown)="onClickLocation(location?.place_id,location?.location_name)">
                    <figure>
                      <img [src]="location_history" alt="">
                    </figure>
                    <span>{{location?.location_name}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="nav_route">
            <ul>
              <li>
                <a routerLinkActive="active" routerLink="/home" >
                  <figure>
                  <img src="./assets/images/HomeLinkButton.png" alt="navigationLogo" width="30" height="30"/>
                  </figure>
                <p>Home</p>
                </a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/dispensaries" (click)="saveTabType('dispensaries')">
                  <figure>
                  <img src="./assets/images/DispensariesLinkPage.png" alt="navigationLogo" width="30" height="30"/>
                  </figure>
                <p>Dispensaries</p>
                </a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/doctor" (click)="saveTabType('doctors')">
                  <figure>
                  <img src="./assets/images/DoctorLinkButton.png" alt="navigationLogo" width="30" height="30"/>
                  </figure>
                <p>Doctors</p>
                </a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/deliveries" (click)="saveTabType('deliveries')">
                  <figure>
                  <img src="./assets/images/DeliveriesLinkButton.png" alt="navigationLogo" width="30" height="30"/>
                  </figure>
                <p>Deliveries</p>

                </a>
              </li>
              <li>
                <a  >
                 <figure>
                 <img src="./assets/images/BrandLinkButton.png" alt="navigationLogo" width="30" height="30"/>
               </figure>
               <p>Brands</p>
               </a>
             </li>   
             <li>
              <a routerLinkActive="active" routerLink="/find-products">
                <figure>
                <img src="./assets/images/ProductLinkButton.png" alt="navigationLogo" width="30" height="30"/>
                </figure>
              <p>Product</p>
              </a>
            </li>
              <li >
                <a routerLinkActive="active" routerLink="/deals">
                  <figure>
                  <img src="./assets/images/DealsLinkButton.png" alt="navigationLogo" width="30" height="30"/>
                </figure>
                <p>Deals</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      
      </div>
      <div class="col-12 col-lg-3 col-md-3 user-box">
        <div class="d-inline-flex justify-content-center" *ngIf="!isLogin">
          <div class="d-flex justify-content-lg-end justify-content-center user_authStatus" >
            <ng-container *ngIf="!isMerchant()">
              <button class="btn btn-primary rounded-pill text-color1" routerLink="/register">Sign Up</button>
              <button class="btn btn-white rounded-pill text-color1" routerLink="/login">Login</button>
            </ng-container>

            <ng-container *ngIf="isMerchant()">
              <button class="btn btn-primary rounded-pill" routerLink="/merchant-dashboard">Merchant Dashboard</button>
            </ng-container>
          </div>
        </div>
        <div class="d-inline-flex justify-content-lg-end justify-content-center" *ngIf="isLogin">
          <div class="head-icons ml-2 d-none">
            <img src="./assets/images/search.svg" alt="" class="d-lg-none d-inline-block cursor-pointer"
              (click)="searchWrapOpen()">
          </div>
          <div class="head-icons d-none">
            <button (click)="openMenu()" class="btn shadow-none text-secondary px-0"><i
                class="material-icons align-middle">menu_open</i></button>
          </div>
          <div class="d-flex justify-content-lg-end justify-content-center position-relative" *ngIf="isLogin">
            <div class="head-icons" routerLink="/notification">
              <span class="material-icons">
                notifications
              </span>
            </div>
            <div class="head-icons" routerLink="/wishlist">
              <span class="material-icons">
                favorite
              </span>
            </div>
            <div class="head-icons" routerLink="/my-cart">
              <span class="material-icons">
                shopping_cart
              </span>
            </div>
            <div class="head-icons" (click)="openPriceDrop()">
              <span class="material-icons">
                person
              </span>
            </div>

            <div class="profileDrop" (click)="openPriceDrop()">
              <div class="d-flex border-bottom pb-3">
                <figure>
                  <img
                    [src]="loggedInUser && loggedInUser?.profileImage ? BASEURL+'user/'+loggedInUser?.profileImage : 'assets/images/user1.svg'"
                    onerror="this.src = 'assets/images/user1.svg'" alt="">
                </figure>
                <div class="userName">
                  <p *ngIf="loggedInUser && loggedInUser?.username">{{loggedInUser?.username}}</p>
                  <p *ngIf="loggedInUser && !loggedInUser?.username">User</p>
                  <p class="cursor-pointer" routerLink="/account">View Account</p>
                </div>
              </div>
              <ul class="list-unstyled">
                <li routerLink="/account-settings"><span class="material-icons mr-2 mt-1">
                    settings
                  </span>Account Settings</li>
                  <li routerLink="/profile-settings"><span class="material-icons mr-2 mt-1">
                    person 
                  </span>My Profile</li>
                <li routerLink="/wishlist"><span class="material-icons mr-2 mt-1">
                    favorite_border
                  </span>Favorites</li>
                <li routerLink="/recently-viewed"><span class="material-icons mr-2 mt-1">
                    visibility
                  </span>Recently Viewed</li>
                <li routerLink="/my-purchase"><span class="material-icons mr-2 mt-1">
                    shopping_cart
                  </span>My Orders</li>
                <li (click)="toLogout()"><span class="material-icons mr-2 mt-1">
                    logout
                  </span>Logout</li>
                <hr>

              </ul>
            </div>
          </div>
        </div>
        <div class="d-inline-flex date-box" [ngClass]="{'dateHide':!isMerchant()}">
          <p>Date: <span>{{current_date | date}}</span></p>
          <!-- {{current_date | date:}} -->
        </div>
      </div>
    </div>
  </div>
</header>
<!-- <nav class="navigation-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="main-menu-holder">
          <div class="mainMenuWrap" (click)="closeMenu()">
            <div class="d-none align-items-center border-bottom py-2 mb-3 ">
              <div class="col-auto">
                <a routerLink='/' class="weblogo">
                  <img draggable="false" src="assets/images/logo.svg" width="130" alt="">
                </a>
              </div>
              <div class="col-auto ml-auto class__menu--p">
                <button class="btn shadow-none text-secondary p-0"><i
                    class="material-icons align-middle">close</i></button>
              </div>
            </div>

            <ul class="menuList list-unstyled m-0 d-lg-flex">
              <li routerLinkActive="active" routerLink="/dispensaries"><div class="icon-holder"><img src="./assets/images/Frame-6.svg" alt=""></div> Dispensaries</li>
              <li routerLinkActive="active" routerLink="/deliveries"><div class="icon-holder"><img src="./assets/images/Frame-1.svg" alt=""></div> Deliveries </li>
              <li routerLinkActive="active" routerLink="/maps"><div class="icon-holder"><img src="./assets/images/Frame-3.svg" alt=""></div> Maps </li>
              <li routerLinkActive="active" routerLink="/doctor"><div class="icon-holder"><img src="./assets/images/Frame-2.svg" alt=""></div> Doctors </li>
              <li routerLinkActive="active" routerLink="/find-products"><div class="icon-holder"><img src="./assets/images/Frame-4.svg" alt=""></div> Products </li>
              <li routerLinkActive="active" routerLink="/deals"><div class="icon-holder"><img src="./assets/images/Frame-5.svg" alt=""></div> Deals </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</nav> -->
