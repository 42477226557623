import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html',
  styleUrls: ['./signup-confirmation.component.scss']
})
export class SignupConfirmationComponent implements OnInit {
  selectedDate : any = undefined;
  maxDt: any;
  constructor(
    public dialogRef: MatDialogRef<SignupConfirmationComponent>) { }

  ngOnInit(): void {
    const now = new Date()
    this.maxDt = new Date(now.setFullYear(now.getFullYear() - 19));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openCalendar() :void {
    let toggleView = document.getElementById('calendarView');
    toggleView?.classList.add('d-block');
    let step1 = document.getElementById('step1');
    step1?.classList.replace('d-block' , 'd-none');
  }

  showStep3() :void {
    let toggleView = document.getElementById('calendarView');
    toggleView?.classList.replace('d-block' , 'd-none');
    let logo = document.getElementById('logo');
    logo?.classList.replace('d-block' , 'd-none');
    let step3 = document.getElementById('step3');
    step3?.classList.add('d-block');

    setTimeout(()=> {
      this.dialogRef.close(this.selectedDate)
    }, 2000)
  }

  noOpenCalendar(){
    let step1 = document.getElementById('step1');
    step1?.classList.replace('d-block' , 'd-none');
    let logo = document.getElementById('logo');
    logo?.classList.replace('d-block' , 'd-none');
    let step3 = document.getElementById('step3');
    step3?.classList.add('d-block');

    setTimeout(()=> {
      this.dialogRef.close()
    }, 2000)
  }

  onSelect(event :any){
    this.selectedDate= event;
  }
}
