import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private storage: StorageService
  ) { }

  private loading = new BehaviorSubject(false);
  isLoading = this.loading.asObservable();

  changeMapCenter = new Subject();

  private Login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.Login.asObservable();

  private user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  public profileUpdate = new BehaviorSubject(false);
  isProfileUpdated = this.profileUpdate.asObservable();

  public currLoc = new BehaviorSubject(this.storage.isCurrLocation());
  isCurrLoc = this.currLoc.asObservable();

  private isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  private emitCatSelect = new BehaviorSubject(false);
  isEmitCatSelect = this.emitCatSelect.asObservable();

  private searchChange = new BehaviorSubject(false);
  isEmitSearchChnage = this.searchChange.asObservable();

  setLoginEmmit(isLogin: boolean) {
    return this.Login.next(isLogin);
  }
  setLoaderEmmit(isLoading: boolean) {
    return this.loading.next(isLoading);
  }

  setCurrLocationEmit(isCurrLoc: boolean){
    return this.currLoc.next(isCurrLoc);
  }

  setProfileUpdateEmit(data: boolean){
    return this.profileUpdate.next(data);
  }

  async setEmitCatSelect(data: boolean): Promise<any>{
    return await this.emitCatSelect.next(data);
  }

  // ALTERNATIVE
  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }

  
  // Search change
  async searchEmit(): Promise<any> {
    return await this.searchChange.next(true);
  }


   changeStatus = new BehaviorSubject<any>(false);
  // ischangeStatus = this.changeStatus.asObservable();


}
