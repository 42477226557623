<div class="bannersec" (click)="data?.merchantId && bannerButtonEmit(data)">
  <img src="{{data?.image}}" alt="" onerror="this.src = './assets/images/no-image.png'">
  <div class="bannercont container"
    [ngClass]="{'text-center' : data?.alignMent==='center', 'text-left' : data?.alignMent==='left'}">
    <span class="pretitle small-t" *ngIf="data?.pretitle"  [ngClass]="{'text-center' : (data?.pretitle?.text_align==='center' || data?.pretitle?.text_align===''), 'text-left' : data?.pretitle?.text_align==='left', 'text-right' : data?.pretitle?.text_align==='right', 'text-justify' : data?.pretitle?.text_align==='justify'}" [ngStyle]="{'font-weight':data?.pretitle?.font_size,'color':data?.pretitle?.color_code}">{{data?.pretitle?.text}}</span>
   
    <span class="title medium-t" *ngIf="data?.subtitle" [ngClass]="{'text-center' : (data?.subtitle?.text_align==='center' || data?.subtitle?.text_align===''), 'text-left' : data?.subtitle?.text_align==='left', 'text-right' : data?.subtitle?.text_align==='right', 'text-justify' : data?.subtitle?.text_align==='justify'}" [ngStyle]="{'font-weight':data?.subtitle?.font_size,'color':data?.subtitle?.color_code}">{{data?.subtitle?.text}}</span> 

    <h1 class="subtitle  " [ngClass]="{'text-center' : (data?.title?.text_align==='center' || data?.title?.text_align===''), 'text-left' : data?.title?.text_align==='left', 'text-right' : data?.title?.text_align==='right', 'text-justify' : data?.title?.text_align==='justify','fs-48': data?.largeTitle}" [ngStyle]="{'font-weight':data?.title?.font_size,'color':data?.title?.color_code}">
      {{data?.title?.text}} 
    </h1>
      
    <span class="pre " *ngIf="data?.posttitle">{{data?.posttitle}}</span>
    
    <div class="d-flex justify-content-center my-3 mx-auto" style="max-width: 550px;" *ngIf="data?.isSearch">
      <div class="inputWrap searh w-75">
        <img src="./assets/images/search.svg" alt="" class="prefixInput">
        <input type="text" placeholder="{{data?.searchPlaceHolder}}" [(ngModel)]="inpStr" (keyup.enter)="tosearchStr()">
      </div>
      <button class="btn btn-primary rounded-pill py-2 px-4 ml-2" (click)="tosearchStr()">Search Now</button>
    </div>
  </div>
</div>
<!-- [ngClass]="{'text-center' : data?.title?.text_align==='center','text-center' : data?.title?.text_align==='', 'text-left' : data?.title?.text_align==='left', 'text-right' : data?.title?.text_align==='right', 'text-justify' : data?.title?.text_align==='justify','color':data?.title?.color_code,'font-weight':data?.title?.font_size, 'fs-48': data?.largeTitle}" -->

<!-- [ngClass]="{'text-center' : data?.subtitle?.text_align==='center','text-center' : data?.subtitle?.text_align==='', 'text-left' : data?.subtitle?.text_align==='left', 'text-right' : data?.subtitle?.text_align==='right', 'text-justify' : data?.subtitle?.text_align==='justify','color':data?.subtitle?.color_code,'font-weight':data?.pretitle?.font_size}" -->

<!-- [ngClass]="{'text-center' : data?.pretitle?.text_align==='center','text-center' : data?.pretitle?.text_align==='', 'text-left' : data?.pretitle?.text_align==='left', 'text-right' : data?.pretitle?.text_align==='right', 'text-justify' : data?.pretitle?.text_align==='justify'}" -->