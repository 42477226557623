
<div class="modal_cont">
    <div class="can_btn">
        <button class="cancelbtn btn-danger" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="tick">
        <mat-icon>done</mat-icon>
    </div>
    <h2>Order Successfull</h2>
    <div class="responsive_table">
        <table>
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Weights</th>
                    <th>Amount</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of modalData?.checkoutAddData">
                    <td>{{item?.products_data?.title}}</td>
                    <td>{{item?.weightPrice[0]?.quantity}}</td>
                    <td>{{item?.products_data?.weights_data?.title}}</td>
                    <td>${{item?.weightPrice[0]?.price}}</td>
                    <td>${{item?.weightPrice[0]?.quantity * item?.weightPrice[0]?.price}}</td>
                </tr>
            </tbody>
        </table>
        <table class="table_sec">
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td><strong>Amount</strong></td>
                <td><strong>${{totalPrice}}</strong></td>
            </tr>
        </table>
    </div>
    
</div>
