<div class="modalwrap modal_newWrap_table scroll-modal">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12">
            <img src="./assets/images/logo.svg" alt="" class="d-block mx-auto mainImage" id="logo">
        </div>  
        </div>
<div class="allInner_infoDetails">
        <div class="row">
            <div class="col-12 title-box">
                <h3>Item Price</h3>
                <button class="close" (click)="closeDialog()"><i class="fa fa-times"></i> </button>
            </div>
        </div>
        <div class="row" *ngIf="data && data?.itemType">
            <div class="col-12">
                <h6>Item Type: {{data?.itemType}}</h6>
            </div>
        </div>
        <div class="row" *ngIf="data && data?.location">
            <div class="col-12">
                <h6>{{data.location}}</h6>
            </div>
        </div>
       
        <div class="row" *ngIf="data && data?.seconderylocation">
            <div class="col-12">
                <h6>{{data.seconderylocation}}</h6>
            </div>
            <!-- <div class="col-sm-6 col-lg-6 title-box">
                <h6 class="mb-3">Duration: {{data.timePeriod}}</h6>
            </div> -->
        </div>
        
        <!-- <div class="row" *ngIf="data && data?.timePeriod">
            <div class="col-12 title-box" *ngFor="let time of data?.timePeriod">
                <h6 class="mb-3">Duration: From {{data.time.startDate}} to {{data?.time?.endDate}}</h6>
            </div>
        </div> -->
        <!-- <div class="row" *ngIf="data && data?.period">
            <div class="col-12 title-box">
                <h6>{{data.period}}</h6>
            </div>
        </div> -->
        <div class="moodalBody itemPrice_modalbody" *ngIf="data?.tableData">
            <ng-container *ngFor="let item of data?.tableData; let i = index">
                <table class="table-controller">
                <tr *ngIf="data && item?.period">
                    <th>Duration: </th>
                    <td>{{item?.period}} {{item?.period > 1 ? 'Months' : 'Month'}}</td>
                </tr>
                <tr *ngIf="data && item?.period">
                    <th>Address:</th>
                    <td>{{item?.address}}</td>
                </tr>
                <tr *ngFor="let brek of item?.priceBreak;">
                    <th>{{brek}}</th>
                    <td>${{(item?.itemCost / item?.period).toFixed(2)}}</td>
                </tr>
                <tr *ngIf="data && item?.itemCost">
                    <th>Amount: </th>
                    <td *ngIf="!(data?.itemType === 'Pin' && item?.period && item?.pinPrice)"> ${{item?.itemCost}}</td>
                    <td *ngIf="data?.itemType === 'Pin' && item?.period && item?.pinPrice"> ${{(item?.itemCost / item?.period).toFixed(2)}} x {{item?.period}} {{ item?.period > 1 ? 'Months' : 'Month' }} = ${{item?.itemCost}}</td>
                </tr>
                <tr *ngIf="data && item?.sc">
                    <th>Service Charge:</th>
                    <td>${{parseFloat(item?.sc)}}</td>
                </tr>
                <tr *ngIf="data && item?.tax">
                    <th>Tax:</th>
                    <td>${{parseFloat(item?.tax)}}</td>
                </tr>
                <tr *ngIf="data && item?.total">
                    <th>Total Amount:</th>
                    <td>${{totalPrice}}</td>
                </tr>
            </table>
                <hr class="line_break" *ngIf="i < data?.tableData.length-1">
            </ng-container>
            
            <div>
                <table class="table-controller">
                    <tr>
                        <th>Total:</th>
                        <td>${{totalPrice}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="moodalBody itemPrice_modalbody" *ngIf="!data?.tableData">
            <table class="table-controller">
                <tr *ngIf="data && data?.period">
                    <th>Duration: </th>
                    <td>{{data?.period}} {{data?.period > 1 ? 'Months' : 'Month'}}</td>
                </tr>
                <tr *ngFor="let brek of data?.priceBreak;">
                    <th>{{brek}}</th>
                    <td>${{(data?.itemCost / data?.period).toFixed(2)}}</td>
                </tr>
                <tr *ngIf="data && data?.itemCost">
                    <th>Amount: </th>
                    <td *ngIf="!(data?.itemType === 'Pin' && data?.period && data?.pinPrice)"> ${{data?.itemCost}}</td>
                    <td *ngIf="data?.itemType === 'Pin' && data?.period && data?.pinPrice"> ${{(data?.itemCost / data?.period).toFixed(2)}} x {{data?.period}} {{ data?.period > 1 ? 'Months' : 'Month' }} = ${{data?.itemCost}}</td>
                </tr>
                <tr *ngIf="data && data?.sc">
                    <th>Service Charge:</th>
                    <td>${{parseFloat(data?.sc)}}</td>
                </tr>
                <tr *ngIf="data && data?.tax">
                    <th>Tax:</th>
                    <td>${{parseFloat(data?.tax)}}</td>
                </tr>
                <tr *ngIf="data && data?.total">
                    <th>Total Amount:</th>
                    <td>${{data?.total ? data?.total : totalPrice}}</td>
                </tr>
                
            </table>
            <div>
                <table class="table-controller" *ngIf="data.tableData">
                    <tr>
                        <th>Total:</th>
                        <td>${{data.totalPrice ? data.totalPrice : totalPrice}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="profile-field btn-holder">
            <button class="btn cancelbtn btn-danger mr-2" (click)="closeDialog()">Edit</button>
            <button class="btn greenbtn" (click)="confirm()">CONFIRM!</button>
        </div>
    </div>
    </div>
</div>