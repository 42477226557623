import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-edit',
  templateUrl: './confirm-edit.component.html',
  styleUrls: ['./confirm-edit.component.scss']
})
export class ConfirmEditComponent implements OnInit {
  isConfirm = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmEditComponent>) { }

  ngOnInit(): void {
    if(this.data === 'step3'){
      setTimeout(()=>{
        this.dialogRef.close(true)
      }, 4000)
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openNextStep() :void {
    // this.isConfirm = true;
    // let toggleView = document.getElementById('step3');
    // toggleView?.classList.add('d-block');
    // let step1 = document.getElementById('step1');
    // step1?.classList.replace('d-block' , 'd-none');
    // let logo = document.getElementById('logo');
    // logo?.classList.replace('d-block' , 'd-none');

    this.dialogRef.close(true);
  }
}
