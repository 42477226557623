import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HelperModule } from '@project/helper/helper.module';
import { BannerComponent } from './banner/banner.component';

import { MaterialModule } from '@project/material.module';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthHeaderComponent } from './auth-header/auth-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalenderComponent } from './calender/calender.component';
import { CalendarModule } from 'angular-calendar';
import { DynamicBannerComponent } from './dynamic-banner/dynamic-banner.component';
import { AuthFooterComponent } from './auth-footer/auth-footer.component';
import { CommonmerchanthearderComponent } from './commonmerchanthearder/commonmerchanthearder.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  BannerComponent,
  AuthHeaderComponent,
  CalenderComponent,
  DynamicBannerComponent,
  AuthFooterComponent,
  CommonmerchanthearderComponent
];

@NgModule({
  declarations: [
    COMPONENTS,
    DynamicBannerComponent,
    AuthFooterComponent,
    CommonmerchanthearderComponent,
  ],
  exports: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    HelperModule,
    MaterialModule,
    PerfectScrollbarModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
