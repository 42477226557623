import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
// import { } from '@types/googlemaps';
// import { } from 'googlemaps';
// import { } from 'node_modules/@types/googlemaps';

@Component({
    selector: 'AutocompleteComponent',
    template: `
      <input class="input"
        type="text"
        [(ngModel)]="autocompleteInput"
        #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 525px"
        >
    `,
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() prefilledAdress: string;
    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    // @Output() setLatLng: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext') addresstext: any;

    autocompleteInput: any;
    queryWait: boolean;

    constructor() {
    }

    ngOnInit() {
        this.autocompleteInput = this.prefilledAdress;
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
            {
                // componentRestrictions: { country: 'US' },
                componentRestrictions: { country: ['US','CA'] },
                types: [this.adressType],  // 'establishment' / 'address' / 'geocode'
                // language: 'en'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            // const latLng = { 
            //     lat: place.geometry.location.lat(),
            //     lng: place.geometry.location.lng()
            // }
            this.invokeEvent(place);
        });
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

}