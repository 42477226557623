import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-profile-fields-edit',
  templateUrl: './profile-fields-edit.component.html',
  styleUrls: ['./profile-fields-edit.component.scss']
})
export class ProfileFieldsEditComponent implements OnInit {
  textInput: any = '';
  selectedDate : any = undefined;
  maxDt: any;
  errorMsg:any;

  otpStep = false;
  otpCode: any;
  configOTP = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px'
    }
  };

  pwdForm: FormGroup;
  showPwd = false;
  showNewPwd = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ProfileFieldsEditComponent>, private fb: FormBuilder,
  private api: ApiService) { 
    const now = new Date()
    this.maxDt = new Date(now.setFullYear(now.getFullYear() - 19));
  }

  ngOnInit(): void {
    if(this.data.value){
      if(this.data.type !== 'dob'){
        this.textInput = this.data.value
      }else{
        this.selectedDate = new Date(this.data.value)
      }
    }
    this.pwdForm = this.fb.group({
      currPwd: ['', [Validators.required, Validators.minLength(8)]],
      newPwd: ['', [Validators.required, Validators.minLength(8)]]
    })
  }

  enableButton():boolean{
    if(this.data.type === 'dob'){
      if(this.selectedDate){
        return true
      }
      return false
    }else {
      if(this.textInput){
        const pattern = new RegExp(this.data.pattern);
        if(pattern.test(this.textInput)){
          if(this.data.type === 'bio' && this.textInput.length > 255){
            return false;
          }else if(this.data.type !== 'bio' || (this.data.type === 'bio' && this.textInput.length <= 255)){
            return true;
          }
        }
        return false;
      }
      return false;
    }
  }

  toSave(){
    if(this.data.type !== 'dob'){
      if(this.textInput){
        const pattern = new RegExp(this.data.pattern);
        if(pattern.test(this.textInput)){
          if(this.data.type === 'bio' && this.textInput.length > 255){
            this.errorMsg = 'You have crossed 255 characters limit'
          }else if(this.data.type !== 'bio' || (this.data.type === 'bio' && this.textInput.length <= 255)){
            this.saveData(this.data.type, this.textInput)
          }
        }else{
          this.errorMsg = 'Invalid input'
        }
      }else{
        this.errorMsg = 'This field is mandatory'
      }
    }else{
      if(this.selectedDate){
        // hit api to save
        this.saveData(this.data.type, this.selectedDate)
      }else{
        this.errorMsg = 'Please select a date'
      }
    }
  }

  saveData(key:any, val:any){
    const obj = new FormData();
    if (key === 'email') {
      obj.set(key, val.trim().toLowerCase());
    } else {
      obj.set(key, val);
    }
    
    this.api.postMultiData('user/updateprofile', obj).subscribe((res:any) => {
      if(res.status === 200){
        this.api.alert(res.message, 'success');
        this.dialogRef.close(true);
      }else{
        this.api.alert(res.message, 'warning');
      }
    }, err => {
      this.api.alert('Something went wrong!!!', 'error');
    })
  }

  onSelect(event :any){
    this.selectedDate= event;
  }

  onOtpChange(event:any){
    if(event.length === 6){
      this.otpCode = event
    }else{
      this.otpCode = ''
    }
  }

  sendOtp(){
    if(this.textInput){
      const pattern = new RegExp(this.data.pattern);
      if(pattern.test(this.textInput)){
        //console.log(this.textInput);
        this.api.post('user/phone/otp', {phone_number: this.textInput}).subscribe((res:any) => {
          if(res.status === 200){
            this.otpStep = true;
            this.api.alert(res.message, 'success');
          }else{
            this.api.alert(res.message, 'warning');
          }
        }, err => {
          //console.log(err);
          this.api.alert('Some error occurred', 'error')
        })
      }else{
        this.errorMsg = 'Please enter your 10 digit phone number';
      }
    }else{
      this.errorMsg = 'This field is mandatory'
    }
  }

  verifyAndSave(){
    this.api.post('user/phone/verify', {otp: this.otpCode}).subscribe((res:any) => {
      if(res.status === 200){
        this.api.alert(res.message, 'success');
        this.dialogRef.close(true);
      }else{
        this.api.alert(res.message, 'warning');
      }
    }, err => {
      this.api.alert('Some error occurred', 'error')
    })
  }

  changePwd(){
    if(this.pwdForm.valid){
      if(this.pwdForm.value.currPwd === this.pwdForm.value.newPwd){
        this.api.alert('New password cannot be same as your current password', 'warning');
      }else{
        const obj = {
          currentPassword: this.pwdForm.value.currPwd,
          newPassword: this.pwdForm.value.newPwd
        }
        this.api.post('web/changepassword', obj).subscribe((res:any) => {
          if(res.status === 200){
            this.api.alert(res.message, 'success');
            this.dialogRef.close();
          }else{
            this.api.alert(res.message, 'warning');
          }
        }, err => {
          this.api.alert('Some error occurred', 'error')
        })
      }
    }else{
      this.pwdForm.markAllAsTouched();
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
