import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit {
  @Input() data:any = true;
  merchantUser: any;
  todayDate: Date;
  BASEURL = environment.IMAGE_BASE_URL
  bussinessType: any;
  dayCount: number;
  constructor(
    private api: ApiService, 
    private event: EventService, 
    private router: Router, 
    private storage: StorageService) {
       this.todayDate = new Date();
    }

  ngOnInit(): void {
    this.getMerchantUser();
    this.getAccountType();
    this.event.isProfileUpdated.subscribe(resp => {
      if(resp){
        this.getMerchantUser();
      }
    })
    this.event.changeStatus.subscribe((event:any) => {
      if (event) {
       this.getAccountType();
      }
    })
  }

  getMerchantUser(){
    this.api.get('merchant/myprofile').subscribe({
      next:(res:any)=>{
        if(res?.status === 200){
          this.merchantUser = res.data
          // console.log("merchantUser",this.merchantUser);
        }
      },error:(err:any)=>{
        this.api.alert('Some error occurred','error')
      }
    })
  }

  toLogout(){
    this.storage.clearUser();
    this.event.setLoginEmmit(false);
    sessionStorage.removeItem('recent-view')
    this.router.navigate(['/home'])
  }

  //////////////////for collect bus. account type/////////////////
  getAccountType(){
    this.api.post('user/merchant_account_status').subscribe({
      next:(res:any)=>{
        if(res?.status == 200){
          this.bussinessType = res.data
          this.getRemainingDays(this.bussinessType.account_info.expiry_date.split('T')[0]);
        }else{
          this.api.alert(res.message,'warning')
        }
      },error:(err:any)=>{
        this.api.alert('Some error occurred','error')
      }
    })
  } 

  getRemainingDays(expDate:any) {
    const currentDate: Date = new Date();
    const timeDiff: number = new Date(expDate).getTime() - currentDate.getTime();
    this.dayCount = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
    // console.log("dayCount",this.dayCount);
  }

}
