import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';

@Component({
  selector: 'app-commonmerchanthearder',
  templateUrl: './commonmerchanthearder.component.html',
  styleUrls: ['./commonmerchanthearder.component.scss']
})
export class CommonmerchanthearderComponent implements OnInit {
  bussinessType: any;
  currentDate: any;
  dayCount: number;
  merchantUser: any;

  constructor(
    private api:ApiService,
    private event: EventService
  ) { }

  ngOnInit(): void {
    this.getAccountType()
    this.currentDate = new Date()
    this.event.changeStatus.subscribe((event:any) => {
        if (event) {
          this.getAccountType();
        }
    })
  }
   //////////////////for collect bus. account type/////////////////
   getAccountType(){
    this.api.post('user/merchant_account_status').subscribe({
      next:(res:any)=>{
        if(res.status == 200){
          this.bussinessType = res.data
          this.getRemainingDays(this.bussinessType.account_info.expiry_date.split('T')[0])
          // this.bussinessType.account_info.expiry_date.split('T')[0] is split because form backend send date and time so its create a one day problem
        }else{
          this.api.alert(res.message,'warning')
        }
      },error:(err:any)=>{
        this.api.alert('Some error occurred','error')
      }
    })
  } 

  getRemainingDays(expDate:any) {
    const currentDate: Date = new Date();
    const timeDiff: number = new Date(expDate).getTime() - currentDate.getTime();
    this.dayCount = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
    console.log("dayCount",this.dayCount);
  }

}
